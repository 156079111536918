import { useState } from "react";
import { Icons } from "../../Assets/icons/icons";
import { FaAngleRight } from "react-icons/fa6";
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TbSend } from "react-icons/tb";
import { FaAngleLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import { RxExit } from "react-icons/rx";
import plus_comment from './../../Assets/icons/plus_comment.png'
import Submit_comment from './../../Assets/icons/Submit_comment.png'
import Item from "antd/es/list/Item";

export default function LeadCard({ closeModal, name, last_name, phone_number }) {
    const [menuStatus, setMenuStatus] = useState(1);
    const [smsMenu, setSmsMenu] = useState(1);
    const [commentMenu, setCommentMenu] = useState(1);
    const [activeState, setActiveState] = useState(1); // Используйте 1, 2 и 3 для трех состояний
    const [buttonStates, setButtonStates] = useState([0, 0, 0]);
    const [subjectState, setSubjectState] = useState(0);
    const [lessonTypeState, setLessonTypeState] = useState(0);
    const [teacherState, setTeacherState] = useState(0);
    const [lessonTimeState, setLessonTimeState] = useState(0);
    const [leadSourceState, setLeadSourceState] = useState(0);

    // General click handler
    const handleClick = (setState) => {
        setState(prevState => (prevState + 1) % 4);
    };

    // Get button text based on state
    const getButtonText = (state) => {
        switch (state) {
            case 1:
                return 'Option 1';
            case 2:
                return 'Option 2';
            case 3:
                return 'Option 3';
            default:
                return 'Sobject';
        }
    };

    const handleModalContentClick = (e) => {
        e.stopPropagation(); // Prevent the modal content clicks from propagating to the modal background
    };

    return (
        <div className="LeadsCard" onClick={closeModal}>
            <div className="LeadsCardContent" onClick={handleModalContentClick}>
                <div className="LeadsCardNav">
                    <button onClick={closeModal} className="LeadsCardNav__closeBtn"><Icons.close /></button>
                    <div className="LeadsCardNavText">
                        <h2>Lead card</h2>
                        <p>Are you sure you want to edit the lead card?</p>
                    </div>
                </div>
                <div className="LeadsCard__menu">
                    <button
                        className={menuStatus === 1 ? 'active' : ''}
                        onClick={() => setMenuStatus(1)}
                    >
                        Information
                    </button>
                    <button
                        className={menuStatus === 2 ? 'active' : ''}
                        onClick={() => setMenuStatus(2)}
                    >
                        Sms
                    </button>
                    <button
                        className={menuStatus === 3 ? 'active' : ''}
                        onClick={() => setMenuStatus(3)}
                    >
                        Comments
                    </button>
                    <button
                        className={menuStatus === 4 ? 'active' : ''}
                        onClick={() => setMenuStatus(4)}
                    >
                        History
                    </button>
                </div>
                {menuStatus === 1 && (
                    <div className="menuChil_information">
                        <div className="menuChil_information__chil">
                            <label htmlFor="">First name*</label>
                            <input type="text" placeholder="john" value={name} />
                        </div>
                        <div className="menuChil_information__chil">
                            <label htmlFor="">Last name*</label>
                            <input type="text" placeholder="Anderson" value={last_name} />
                        </div>
                        <div className="menuChil_information__chil">
                            <label htmlFor="">Phone number*</label>
                            <input type="text" placeholder="+998" value={phone_number} />
                        </div>
                        <div className="menuChil_information__chil">
                            <label htmlFor="subject">Select subject*</label>
                            <div className="LeadModalCardAdd">
                                <button
                                    id="subject"
                                    className={`button-state-${subjectState}`}
                                    onClick={() => handleClick(setSubjectState)}
                                >
                                    <p>{getButtonText(subjectState)}</p>
                                    <span>
                                        {subjectState === 1 && <IoIosArrowDown />}
                                        {subjectState === 2 && <IoIosArrowUp />}
                                        {subjectState === 3 && <AiOutlineCloseCircle />}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="menuChil_information__chil">
                            <label htmlFor="lessonType">Select lesson type*</label>
                            <div className="LeadModalCardAdd">
                                <button
                                    id="lessonType"
                                    className={`button-state-${lessonTypeState}`}
                                    onClick={() => handleClick(setLessonTypeState)}
                                >
                                    <p>{getButtonText(lessonTypeState)}</p>
                                    <span>
                                        {lessonTypeState === 1 && <IoIosArrowDown />}
                                        {lessonTypeState === 2 && <IoIosArrowUp />}
                                        {lessonTypeState === 3 && <AiOutlineCloseCircle />}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="menuChil_information__chil">
                            <label htmlFor="teacher">Select teacher*</label>
                            <div className="LeadModalCardAdd">
                                <button
                                    id="teacher"
                                    className={`button-state-${teacherState}`}
                                    onClick={() => handleClick(setTeacherState)}
                                >
                                    <p>{getButtonText(teacherState)}</p>
                                    <span>
                                        {teacherState === 1 && <IoIosArrowDown />}
                                        {teacherState === 2 && <IoIosArrowUp />}
                                        {teacherState === 3 && <AiOutlineCloseCircle />}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="menuChil_information__chil">
                            <label htmlFor="lessonTime">Select lesson time*</label>
                            <div className="LeadModalCardAdd">
                                <button
                                    id="lessonTime"
                                    className={`button-state-${lessonTimeState}`}
                                    onClick={() => handleClick(setLessonTimeState)}
                                >
                                    <p>{getButtonText(lessonTimeState)}</p>
                                    <span>
                                        {lessonTimeState === 1 && <IoIosArrowDown />}
                                        {lessonTimeState === 2 && <IoIosArrowUp />}
                                        {lessonTimeState === 3 && <AiOutlineCloseCircle />}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="menuChil_information__chil">
                            <label htmlFor="leadSource">Select lead source*</label>
                            <div className="LeadModalCardAdd">
                                <button
                                    id="leadSource"
                                    className={`button-state-${leadSourceState}`}
                                    onClick={() => handleClick(setLeadSourceState)}
                                >
                                    <p>{getButtonText(leadSourceState)}</p>
                                    <span>
                                        {leadSourceState === 1 && <IoIosArrowDown />}
                                        {leadSourceState === 2 && <IoIosArrowUp />}
                                        {leadSourceState === 3 && <AiOutlineCloseCircle />}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {menuStatus === 2 && (
                    <div className="menuChil_sms">
                        {smsMenu === 1 ? (
                            <div className="menuChil_sms_messages">
                                <div className="menuChil_sms_message">
                                    <div className="sms_border_box">
                                        <div className="sms_box">
                                            <div className="sms_box_name">
                                                <h2>John Anderson</h2>
                                                <p>Sent</p>
                                            </div>
                                            <div className="sms_box_massage">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                            <div className="sms_box_sendtime">
                                                <p>02.04.2024  18:42 </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sms_border_box">
                                        <div className="sms_box">
                                            <div className="sms_box_name">
                                                <h2>John Anderson</h2>
                                                <p>Sent</p>
                                            </div>
                                            <div className="sms_box_massage">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                            <div className="sms_box_sendtime">
                                                <p>02.04.2024  18:42 </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sms_border_box">
                                        <div className="sms_box">
                                            <div className="sms_box_name">
                                                <h2>John Anderson</h2>
                                                <p>Sent</p>
                                            </div>
                                            <div className="sms_box_massage">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                            </div>
                                            <div className="sms_box_sendtime">
                                                <p>02.04.2024  18:42 </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => setSmsMenu(2)} className="menuChil_sms_message_sendBtn"><span><TbSend /></span><p>New sms</p></button>
                            </div>
                        ) : (
                            <div className="menuChil_sms_messagesSend">
                                <textarea placeholder="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur necessitatibus eos molestiae omnis consequuntur accusantium dolores id placeat. Mollitia, quasi?"></textarea>
                                <div className="menuChil_sms_messagesSend_menuBtns">
                                    <button className="Massageback_menuBtns"><FaAngleLeft /></button>
                                    <div className="BlaHuila">
                                        <button className="MassageSure_menuBtns">Name Surname</button>
                                        <button className="MassageSubject_menuBtns">Subject name</button>
                                        <button className="MassageTeacher_menuBtns">Teacher name</button>
                                    </div>
                                    <button className="Massageback_menuBtns"><FaChevronRight /></button>
                                </div>
                                <div className="menuChil_sms_messagesSend_endBtns">
                                    <button onClick={() => setSmsMenu(1)}> <span><RxExit /></span><p>Back to sms history</p></button>
                                    <button><span><TbSend /></span><p>Send</p></button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {menuStatus === 3 && (
                    <div className="menuChil_sms">
                        {commentMenu === 1 ? (
                            <div className="menuChil_sms_messages">
                                <div className="menuChil_sms_message">
                                    <div className="menuChil_sms_message_box">
                                        <h4 className="menuChil_sms_message_date">22.05.2024 02:33</h4>
                                        <div className="menuChil_sms_message_box_texts">
                                            <h4 className="menuChil_sms_message_box_title">Jhon Anderson</h4>
                                            <p className="menuChil_sms_message_box_desck">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore quo laborum ipsum quasi pariatur qui possimus tempora esse quidem aliquid?</p>
                                        </div>
                                    </div>
                                    <div className="menuChil_sms_message_box">
                                        <h4 className="menuChil_sms_message_date">22.05.2024 02:33</h4>
                                        <div className="menuChil_sms_message_box_texts">
                                            <h4 className="menuChil_sms_message_box_title">Jhon Anderson</h4>
                                            <p className="menuChil_sms_message_box_desck">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore quo laborum ipsum quasi pariatur qui possimus tempora esse quidem aliquid?</p>
                                        </div>
                                    </div>
                                    <div className="menuChil_sms_message_box">
                                        <h4 className="menuChil_sms_message_date">22.05.2024 02:33</h4>
                                        <div className="menuChil_sms_message_box_texts">
                                            <h4 className="menuChil_sms_message_box_title">Jhon Anderson</h4>
                                            <p className="menuChil_sms_message_box_desck">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore quo laborum ipsum quasi pariatur qui possimus tempora esse quidem aliquid?</p>
                                        </div>
                                    </div>
                                    <div className="menuChil_sms_message_box">
                                        <h4 className="menuChil_sms_message_date">22.05.2024 02:33</h4>
                                        <div className="menuChil_sms_message_box_texts">
                                            <h4 className="menuChil_sms_message_box_title">Jhon Anderson</h4>
                                            <p className="menuChil_sms_message_box_desck">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore quo laborum ipsum quasi pariatur qui possimus tempora esse quidem aliquid?</p>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => setCommentMenu(2)} className="menuChil_sms_message_sendBtn"><div className="img_menuChil_sms_message_sendBtn"><img src={plus_comment} alt="" /></div> <p>Add new comment</p></button>
                            </div>
                        ) : (
                            <div className="menuChil_sms_messagesSend">
                                <textarea placeholder="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur necessitatibus eos molestiae omnis consequuntur accusantium dolores id placeat. Mollitia, quasi?"></textarea>
                                <div className="menuChil_sms_messagesSend_menuBtns">
                                    <button className="Massageback_menuBtns"><FaAngleLeft /></button>
                                    <div className="BlaHuila">
                                        <button className="MassageSure_menuBtns">Name Surname</button>
                                        <button className="MassageSubject_menuBtns">Subject name</button>
                                        <button className="MassageTeacher_menuBtns">Teacher name</button>
                                    </div>
                                    <button className="Massageback_menuBtns"><FaChevronRight /></button>
                                </div>
                                <div className="menuChil_sms_messagesSend_endBtns">
                                    <button onClick={() => setCommentMenu(1)}><span><RxExit /></span><p>Back to comment history</p>  </button>
                                    <button><div className="img_menuChil_sms_message_sendBtn"><img src={Submit_comment} alt="" /></div><p>Submit</p></button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {menuStatus === 4 && (
                    <div className="menuChil_sms_message menuChil_sms_message_history">
                        <div className="menuChil_sms_message_box">
                            <h4 className="menuChil_sms_message_status">Send</h4>
                            <h4 className="menuChil_sms_message_date">22.05.2024 02:33</h4>
                            <div className="menuChil_sms_message_box_texts">
                                <h4 className="menuChil_sms_message_box_title">Class time has been changed</h4>
                                <p className="menuChil_sms_message_box_desck">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore quo laborum ipsum quasi pariatur qui possimus tempora esse quidem aliquid?</p>
                            </div>
                        </div>
                        <div className="menuChil_sms_message_box">
                            <h4 className="menuChil_sms_message_status">Send</h4>
                            <h4 className="menuChil_sms_message_date">22.05.2024 02:33</h4>
                            <div className="menuChil_sms_message_box_texts">
                                <h4 className="menuChil_sms_message_box_title">Class time has been changed</h4>
                                <p className="menuChil_sms_message_box_desck">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore quo laborum ipsum quasi pariatur qui possimus tempora esse quidem aliquid?</p>
                            </div>
                        </div>
                        <div className="menuChil_sms_message_box">
                            <h4 className="menuChil_sms_message_status">Send</h4>
                            <h4 className="menuChil_sms_message_date">22.05.2024 02:33</h4>
                            <div className="menuChil_sms_message_box_texts">
                                <h4 className="menuChil_sms_message_box_title">Class time has been changed</h4>
                                <p className="menuChil_sms_message_box_desck">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore quo laborum ipsum quasi pariatur qui possimus tempora esse quidem aliquid?</p>
                            </div>
                        </div>
                        <div className="menuChil_sms_message_box">
                            <h4 className="menuChil_sms_message_status">Send</h4>
                            <h4 className="menuChil_sms_message_date">22.05.2024 02:33</h4>
                            <div className="menuChil_sms_message_box_texts">
                                <h4 className="menuChil_sms_message_box_title">Class time has been changed</h4>
                                <p className="menuChil_sms_message_box_desck">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore quo laborum ipsum quasi pariatur qui possimus tempora esse quidem aliquid?</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="LeadsCard_bottomBtns">
                    <button id="save">Save</button>
                    <button onClick={closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );

}
