import React from 'react';
import './Schlude.css'; // Importing the CSS file
import { TbClockHour3 } from "react-icons/tb";
import { FiUser } from "react-icons/fi";
import { PiTelevisionSimple } from "react-icons/pi";
import { RiGroupLine } from "react-icons/ri";
import { IoMdAlarm } from "react-icons/io";
import { SlGraduation } from "react-icons/sl";
import { FiUsers } from "react-icons/fi";
import { CiShare1 } from "react-icons/ci";


export default function Schedule() { // Receive 'data' as a prop

    const data = [
        {
            num: 18,
            lessonTime: '08:00 - 09:30',
            name: 'Group 52',
            teacherName: 'Mr. Smith',
            subjectName: 'General English: Indermatade level',
            level: 'Grade 10',
            lessonType: 'Regular',
            room: 'Room 101',
            students: ['Alice', 'Bob', 'Charlie']
        },
        {
            num: 18,
            lessonTime: '09:45 - 11:15',
            name: 'English Literature',
            teacherName: 'Ms. Johnson',
            subjectName: 'Literature',
            level: 'Grade 10',
            lessonType: 'Regular',
            room: 'Room 102',
            students: ['David', 'Emily', 'Frank']
        },
        // Add more lesson entries as needed
    ];


    return (
        <div style={{ overflowY: 'auto' }} className="Schedule">
            <div className="table headerTable">
                <p><span className='pust'></span></p>
                <p className="tableTextMin nomblya">#</p>
                <p className="tableText lessonblya" >Lesson time</p>
                <p className="tableText nameblya">Name</p>
                <p className="tableText teacherblya">Teacher name</p>
                <p className="tableTextMax Subjectblya">Subject name: level</p>
                <p className="tableText lessonblyaType">Lesson type</p>
                <p className="tableText RoomBlya">Room</p>
                <p className="tableText StudentBlya">Students</p>
            </div>
            <div className="ContentTable">
                <div className="table ">
                    <p><span className='pust'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour "><p className='TableRed'><IoMdAlarm size={"14px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table ">
                    <p><span className='pust'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour"><p className='TableRed'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Individual</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table ">
                    <p><span className='pust'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour"><p className='TableRed'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table ">
                    <p><span className='pust'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour"><p className='TableRed'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Individual</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>

                </div>
                <div className="table">
                    <p><span className='ebatsavrot'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour ebatsavrotTime"><p className='TableBlue'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table">
                    <p><span className='ebatsavrot'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour ebatsavrotTime"><p className='TableBlue'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table">
                    <p><span className='ebatsavrot'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour ebatsavrotTime"><p className='TableBlue'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table">
                    <p><span className='ebatsavrot'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour ebatsavrotTime"><p className='TableBlue'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table TableGreen">
                    <p><span className='pust'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour ebatsavrotgreen"><p lassName='TableGreenw'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table TableGreen">
                    <p><span className='pust'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour ebatsavrotgreen"><p lassName='TableGreenw'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
                <div className="table TableGreen">
                    <p><span className='pust'></span></p>
                    <p className="tableTextMin nomblya">12</p>
                    <p className="tableText tableTextHour ebatsavrotgreen"><p lassName='TableGreenw'><IoMdAlarm size={"20px"} />09:00 - 10:00</p></p>
                    <p className="tableText nameblya">Group 52</p>
                    <p className="tableText tableTextUser teacherblya"><FiUser size={"20px"} />Mr. Johnson</p>
                    <p className="tableTextMax Subjectblya"><SlGraduation size={"20px"} />General English:Indermatade level</p>
                    <p className="tableText tableTextGroup lessonblyaType"><PiTelevisionSimple size={"20px"} /> Group</p>
                    <p className="tableText tabletextRoom RoomBlyaa"><p>Room 2-3</p></p>
                    <p className="tableText tableTextUsers StudentBlya"><FiUsers size={"20px"} />18 students</p>
                    <p className='TableShare'><CiShare1 /></p>
                </div>
            </div>
        </div>
    )
}
