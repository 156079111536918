import React, { useState } from "react";
import { Icons } from "../../../Assets/icons/icons";
import { FaRegCircleCheck } from "react-icons/fa6";
import { Select } from "antd";

export default function EditTableModal({ editTable, setEditTable }) {
  const [EditTableModalSecond, setEditTableModalSecond] = useState(false);
  const [AddEditTableModalQuest, setAddEditTableModalQuest] = useState(false);

  function handleOpenQuestModal() {
    setAddEditTableModalQuest(true);
    setEditTable(false);
  }

  function handleGoBackModal() {
    setEditTable(true);
    setAddEditTableModalQuest(false);
  }

  function handleOpenSecondModal() {
    setEditTable(false);
    setAddEditTableModalQuest(false);
    setEditTableModalSecond(true);
    setTimeout(() => {
      setEditTableModalSecond(false);
    }, 1000);
  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <div className={editTable ? "EditTableModal NewCustomerModal" : "none"}>
        <div className="NewCustomerModalHeader">
          <div className="" onClick={() => setEditTable(false)}>
            <Icons.close />
          </div>
        </div>
        <div className="EditTableModalSelects">
          <div className="EditTableModalTitles">
            <div className="MakeNewStudentTitle">
              <h2>Add an individual lesson</h2>
              <p>Adding new individual lesson for student</p>
            </div>
            <div className="MakeNewStudentSecondTitle">
              <p>
                Create individual lesson for <span>Alisher Atajanov</span>
              </p>
            </div>
          </div>

          <div className="NewCustomerModalSelects newLeaadCard_studentMenu1_chil_form">
            <div>
              <label htmlFor="">
                Group name (optional)
                <span>
                  <Icons.questMark />
                </span>
              </label>
              <input type="text" placeholder="Group name" />
            </div>
            <div>
              <label htmlFor="">
                Select course<span>*</span>
              </label>
              <Select
                defaultValue="select"
                className="studentMenu1_chil_form_selectt"
                style={{
                  width: "213px",
                  height: "45px",
                  color: "#707683",
                  outline: " none",
                  borderRadius: "100px!important",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "select",
                    label: "Select",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
            <div>
              <label htmlFor="">
                Select teacher <span>*</span>
              </label>
              <Select
                defaultValue="select"
                className="studentMenu1_chil_form_selectt"
                style={{
                  width: "213px",
                  height: "45px",
                  color: "#707683",
                  outline: " none",
                  borderRadius: "100px!important",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "select",
                    label: "Select",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
            <div>
              <label htmlFor="">
                Teacher percentage<span>*</span>
              </label>
              <input type="text" placeholder="Disabled" />
            </div>
            <div>
              <label htmlFor="">
                Select days<span>*</span>
              </label>
              <Select
                defaultValue="select"
                className="studentMenu1_chil_form_selectt"
                style={{
                  width: "213px",
                  height: "45px",
                  color: "#707683",
                  outline: " none",
                  borderRadius: "100px!important",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "select",
                    label: "Select",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
            <div>
              <label htmlFor="">
                Select start time<span>*</span>
              </label>
              <Select
                defaultValue="select"
                className="studentMenu1_chil_form_selectt"
                style={{
                  width: "213px",
                  height: "45px",
                  color: "#707683",
                  outline: " none",
                  borderRadius: "100px!important",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "select",
                    label: "Select",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
            <div>
              <label htmlFor="">
                Select room <span>*</span>{" "}
              </label>
              <Select
                defaultValue="select"
                className="studentMenu1_chil_form_selectt"
                style={{
                  width: "213px",
                  height: "45px",
                  color: "#707683",
                  outline: " none",
                  borderRadius: "100px!important",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "select",
                    label: "Select",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
            <div>
              <label htmlFor="">
                Select start day <span>*</span>{" "}
              </label>
              <Select
                defaultValue="select"
                className="studentMenu1_chil_form_selectt"
                style={{
                  width: "213px",
                  height: "45px",
                  color: "#707683",
                  outline: " none",
                  borderRadius: "100px!important",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "select",
                    label: "Select",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
          </div>
          <div className="MakeNewStudentInformation">
            <div className="MakeNewStudentInformationUp">
              <div className="MakeNewStudentInformationUpOne">
                <span>
                  Total number of lessons: <p> 165</p>
                </span>
                <span>
                  Total study duration: <p> 6 months</p>
                </span>
              </div>
              <div className="MakeNewStudentInformationUpTwo">
                <span>
                  Start time: <p> 17:00</p>
                </span>
                <span>
                  End time: <p> 19:00</p>
                </span>
              </div>
              <div className="MakeNewStudentInformationUpThree">
                <span>
                  Start day: <p> May 15</p>
                </span>
                <span>
                  End day: <p> December 15</p>
                </span>
              </div>
            </div>
            <div className="MakeNewStudentInformationDown">
              <div className="MakeNewStudentInformationDownOne">
                <span>
                  Start day: <p> May 15</p>
                </span>
                <span>
                  Monthly discount:<p> 28 000 so’m</p>
                </span>
              </div>
              <div className="MakeNewStudentInformationDownTwo">
                <span>
                  Total payment:<p> 1 200 000 so’m</p>
                </span>
                <span>
                  Total discount:<p> 168 000 so’m</p>
                </span>
              </div>
            </div>
          </div>
          <div className="AddSalaryModalButtons">
            <div className="ConFimModalAddStudButtons">
              <button onClick={() => setEditTable(false)}>Cancel</button>
              <button
                style={{ background: "#005EEB", border: "none" }}
                id="YesConFimModalAddStudButtons"
                onClick={handleOpenQuestModal}
              >
                Confrim
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          AddEditTableModalQuest
            ? "ConFimModalAddStud addSalaryQuestModalCheck"
            : "none"
        }
      >
        <div
          className="ConFimModalAddStudClose"
          onClick={() => setAddEditTableModalQuest(false)}
        >
          <Icons.close />
        </div>
        <div className="ConFimModalAddStudTitle ">
          <h2>Confrim status change</h2>
          <p>
            Do you approve the change
            <br /> of student status ?
          </p>
        </div>
        <div className="ConFimModalAddStudButtons addSalaryQuestModalButton">
          <button onClick={handleGoBackModal} style={{ width: "100%" }}>
            Go back
          </button>
          <button
            style={{ background: "#005EEB", border: "none", width: "100%" }}
            id="YesConFimModalAddStudButtons"
            onClick={handleOpenSecondModal}
          >
            Yes
          </button>
        </div>
      </div>

      <div className={EditTableModalSecond ? "LastConFimModalAddStud" : "none"}>
        <div className="ConFimModalAddStudLogo">
          <span>
            <FaRegCircleCheck />
          </span>
        </div>
        <div className="LastConFimModalAddStudTitle">
          <h2>Status changed</h2>
          <p>Status changed successfully</p>
        </div>
      </div>
    </>
  );
}
