import React, { useState } from "react";
import { Icons } from "../../../Assets/icons/icons";
import { FaRegCircleCheck } from "react-icons/fa6";
import { Select } from "antd";

export default function ChangeStatusModal({ ChangeStatus, setChangeStatus }) {
  const [ChangeStatusModalSecond, setChangeStatusModalSecond] = useState(false);
  const [AddChangeStatusModalQuest, setAddChangeStatusModalQuest] =
    useState(false);

  function handleOpenQuestModal() {
    setAddChangeStatusModalQuest(true);
    setChangeStatus(false);
  }

  function handleGoBackModal() {
    setChangeStatus(true);
    setAddChangeStatusModalQuest(false);
  }

  function handleOpenSecondModal() {
    setChangeStatus(false);
    setAddChangeStatusModalQuest(false);
    setChangeStatusModalSecond(true);
    setTimeout(() => {
      setChangeStatusModalSecond(false);
    }, 1000);
  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className="ChangeStatusModal">
      <div
        className={ChangeStatus ? "AddSalaryModal NewCustomerModal" : "none"}
      >
        <div className="NewCustomerModalHeader">
          <div className="" onClick={() => setChangeStatus(false)}>
            <Icons.close />
          </div>
        </div>
        <div className="NewCustomerModalTitles">
          <p>Change student status</p>
          <span>Change a student's current status</span>
        </div>
        <div className="ChangeStatusModalInfo">
          <p>Alisher Atajanov</p>
          <Select
            defaultValue="select"
            className="studentMenu1_chil_form_selectt "
            style={{
              width: "213px",
              height: "45px",
              color: "#707683",
              outline: "none",
              borderRadius: "100px!important",
            }}
            onChange={handleChange}
            options={[
              {
                value: "Active",
                label: <div className="statusWidth">Active</div>,
              },
              {
                value: "Frozen",
                label: (
                  <div
                    className="statusWidth"
                    style={{ background: "#FFB946" }}
                  >
                    Frozen
                  </div>
                ),
              },
              {
                value: "Stopped",
                label: (
                  <div
                    className="statusWidth"
                    style={{ background: "#F7685B" }}
                  >
                    Stopped
                  </div>
                ),
              },
              {
                value: "Finished",
                label: (
                  <div
                    className="statusWidth"
                    style={{ background: "#33A9FF" }}
                  >
                    Finished
                  </div>
                ),
              },
            ]}
          />
        </div>
        <div className="AddSalaryModalButtons">
          <div className="ConFimModalAddStudButtons">
            <button onClick={() => setChangeStatus(false)}>Cancel</button>
            <button
              style={{ background: "#005EEB", border: "none" }}
              id="YesConFimModalAddStudButtons"
              onClick={handleOpenQuestModal}
            >
              Confrim
            </button>
          </div>
        </div>
      </div>

      <div
        className={
          AddChangeStatusModalQuest
            ? "ConFimModalAddStud addSalaryQuestModalCheck"
            : "none"
        }
      >
        <div
          className="ConFimModalAddStudClose"
          onClick={() => setAddChangeStatusModalQuest(false)}
        >
          <Icons.close />
        </div>
        <div className="ConFimModalAddStudTitle ">
          <h2>Confrim status change</h2>
          <p>
            Do you approve the change
            <br /> of student status ?
          </p>
        </div>
        <div className="ConFimModalAddStudButtons addSalaryQuestModalButton">
          <button onClick={handleGoBackModal} style={{ width: "100%" }}>
            Go back
          </button>
          <button
            style={{ background: "#005EEB", border: "none", width: "100%" }}
            id="YesConFimModalAddStudButtons"
            onClick={handleOpenSecondModal}
          >
            Yes
          </button>
        </div>
      </div>

      <div
        className={ChangeStatusModalSecond ? "LastConFimModalAddStud" : "none"}
      >
        <div className="ConFimModalAddStudLogo">
          <span>
            <FaRegCircleCheck />
          </span>
        </div>
        <div className="LastConFimModalAddStudTitle">
          <h2>Status changed</h2>
          <p>Status changed successfully</p>
        </div>
      </div>
    </div>
  );
}
