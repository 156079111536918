import React, { useState, useEffect, useRef } from "react";
import { Icons } from "../../Assets/icons/icons";
import { IoSearchSharp } from "react-icons/io5";
import { TbFilter } from "react-icons/tb";
import { FiUser } from "react-icons/fi";
import { FaChevronDown } from "react-icons/fa6";
import { RiMoreFill } from "react-icons/ri";
import { SlGraduation } from "react-icons/sl";
import { PiTelevisionSimple } from "react-icons/pi";
import { RiLinkUnlinkM } from "react-icons/ri";
import { RxPinRight } from "react-icons/rx";
import { TfiTime } from "react-icons/tfi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import ModalMake from "./../../components/ModalMake/ModalMake";
import LeadCard from "./LeadCard";
import NewLeadModal from "./NewLeadModal";
import img from "../../Assets/teacher.png";
import "./leads.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { BsCheck2Circle } from "react-icons/bs";
import { TbUserPlus } from "react-icons/tb";
import { TbSend } from "react-icons/tb";
import { LuFolderDown } from "react-icons/lu";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RiShareBoxLine } from "react-icons/ri";
import SecMake from "../../components/ModalMake/SeconMake";
import SeconDel from "../../components/DeleteModal/SeconDel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaTimes } from 'react-icons/fa';
import { VscDebugRestart } from "react-icons/vsc";
import { Link } from "react-router-dom";
import MakeaStudent from "../../components/MakeaStudent/MakeaStudent";
import RemuveCard from "../../components/RemuveCard/RemuveCard";
import DeleteCard from "../../components/DeleteCard/DeleteCard";
import axios from "axios";
import Loading from "../Loading";


const Leads = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const menu = useRef(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [isRange, setIsRange] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const handleButtonClick = () => {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setShowDatePicker(!showDatePicker);
  };

  const handleSingleDateChange = (date) => {
    setTempStartDate(date);
    setTempEndDate(null);
  };

  const handleRangeDateChange = (dates) => {
    const [start, end] = dates;
    setTempStartDate(start);
    setTempEndDate(end);
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-GB", options);
  };

  const handleToggle = () => {
    setIsRange(!isRange);
    setTempStartDate(null);
    setTempEndDate(null);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
  };

  const handleApply = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setShowDatePicker(false);
  };

  const [lessonTimeState, setLessonTimeState] = useState(0);
  const [BranchState, setBranchState] = useState(0);
  const [SubjectState, setSubjectState] = useState(0);
  const [LessonTypeState, setLessonTypeState] = useState(0);
  const [FormState, setFormState] = useState(0);
  const [XuyOdinState, setXuyOdinState] = useState(0);
  const [XuyDvaState, setXuyDvaState] = useState(0);
  const [XuyTriState, setXuyTriState] = useState(0);
  const [XuyChetireState, setXuyChetireState] = useState(0);

  const resetAll = () => {
    setLessonTimeState(false);
    setBranchState(false);
    setSubjectState(false);
    setFormState(false);
  };

  // General click handler
  const handleClick = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  const handleClickSecond = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  const SubjectClick = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  const HandleLessonType = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  const handleLessonForm = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  const PidorOdin = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  const PidorDwa = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  const PidorTri = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  const PidorChetire = (setState) => {
    setState(prevState => {
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      return 1;
    });
  };

  // Get button text based on state
  const getButtonText = (state) => {
    switch (state) {
      case 1:
        return 'Room';
      case 2:
        return 'Option 2';
      case 3:
        return 'Option 3';
      default:
        return 'Sobject';
    }
  };

  const searchContainerRef = useRef(null);


  const handleClickOutside = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [expanded]);

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const toggleDropdownMenu = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const [openDropdownIndex2, setOpenDropdownIndex2] = useState(null);

  const toggleDropdownMenu2 = (index) => {
    setOpenDropdownIndex2(openDropdownIndex === index ? null : index);
    setIsOpenModal(!isOpenModal);
  };



  const [loadingActive, setLoadingActive] = useState(false)
  const [products, setProducts] = useState([]);
  const [leads, setLeads] = useState([]);
  const [leadId, setLeadId] = useState(null);

  const storedToken = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  const getLead = async () => {
    setLoadingActive(true)
    try {
      const { data } = await axios.get("https://api.quickhub.uz/api/lead/leads/", {
        headers: {
          'Authorization': `Token ${storedToken}`,
          'Content-Type': 'application/json',
        },
      });
      setLeads(data);
      console.log(data, "Lead");
      setLoadingActive(false)
    } catch (err) {
      console.error(err);
    }
  };

  const getTable = async () => {
    setLoadingActive(true)
    try {
      const { data } = await axios.get("https://api.quickhub.uz/api/lead/applicants/", {
        headers: {
          'Authorization': `Token ${storedToken}`,
          'Content-Type': 'application/json',
        },
      });
      setProducts(data);
      console.log(data, "Aplication");
      setLoadingActive(false)
    } catch (err) {
      console.error(err);
    }
  };

  const postLead = async () => {
    setLoadingActive(true)
    try {
      const { data } = await axios.post("https://api.quickhub.uz/api/lead/transfer-applications-to-lead/", {
        application_ids: selectId,
        created_by: userId
      }, {
        headers: {
          'Authorization': `Token ${storedToken}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(data, "post");
      setLoadingActive(false)
      getTable()
      getLead()
      console.log(selectId);
    } catch (err) {
      console.error(err);
      console.log(selectId);
    }
  };
  useEffect(() => {
    getTable();
    getLead()
  }, []);

  const [rowClick, setRowClick] = useState(true);
  const [selectId, setSelectId] = useState([]);
  const [changeId, setChangeId] = useState([]);

  const handleIdClick = (id) => {
    setSelectId([id])
    console.log(id);
  };

  const handleRowClick = () => {
    setShowButtons(prevState => !prevState);
  };

  const handleSelectionChange = (e) => {
    const mainCheckboxClicked = e.value.length !== selectedProducts.length;
    setSelectedProducts(e.value);
    setShowButtons(mainCheckboxClicked && e.value.length > 0);
    setSelectId(e.value.map(item => item.id))
    console.log(selectId);
    console.log(e.value.map(item => item.id));
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await products.setProducts();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  const [active, setActive] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenSelect, setIsOpenSelect] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputValue);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };


  useEffect(() => {
    // Обновляем showButtons при изменении состояния выбора продуктов
    setShowButtons(active && (selectedProducts.length > 0 || selectAll));
  }, [selectedProducts, selectAll]);

  useEffect(() => {
    setShowButtons(active && (selectedItems.length > 0 || selectAll));
  }, [active, selectedItems, selectAll]);

  const handleOpenModal = () => {
    setShowModal(true);
    setShowSecMake(false); // Закрыть SecMake, если он открыт

  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowSecMake(false);
  };

  const handleYesModal = () => {
    setShowModal(false);
    setShowSecMake(true); // Открыть SecMake
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    setShowDeleteModal(false);
    setShowSeconDel(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const closeModalOnOff = () => {
    setIsOpenModal(!isOpenModal);
  };

  const [newLeadModal, setNewLeadModal] = useState(false);
  const handlePageChangeTable = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageChangeTable(pageNumber);
    }
  };

  const items = [
    {
      label: "Make a lead",
      icon: <BsCheck2Circle />,
      command: handleOpenModal, // Присвоение функции обработчика
      className: "menu-item-lead",
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: handleDeleteClick, // Присвоение функции обработчика
      className: "menu-item-delete",
    },
  ];

  const [showSecMake, setShowSecMake] = useState(false);
  const [showSeconDel, setShowSeconDel] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isStudentModalVisible, setStudentModalVisibility] = useState(false);
  const [isStudentModalVisibleSecond, setStudentModalVisibilitySecond] = useState(false);
  const [isStudentModalVisibleDel, setStudentModalVisibilityDel] = useState(false);
  const rowsPerPage = 10; // Adjust as needed
  const totalPages = Math.ceil(products.length / rowsPerPage);
  const [isDropdownOpen, setIsOpenDownOpen] = useState(false);

  useEffect(() => {
    if (showSecMake) {
      const timer = setTimeout(() => {
        setShowSecMake(false);
      }, 1000); // 2 секунды

      return () => clearTimeout(timer); // Очистка таймера при размонтировании
    }
  }, [showSecMake]);

  useEffect(() => {
    if (showSeconDel) {
      const timer = setTimeout(() => {
        setShowSeconDel(false);
      }, 1000); // 2 секунды

      return () => clearTimeout(timer); // Очистка таймера при размонтировании
    }
  }, [showSeconDel]);

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleResetDates = (event) => {
    event.stopPropagation(); // Чтобы не вызывать handleButtonClick при нажатии на X
    setStartDate(null);
    setEndDate(null);
  };

  // Функция для переключения видимости выпадающего списка
  const toggleDropdownTeacher = () => {
    setDropdownVisible(prev => !prev);
  };

  const handleOpenStudentModal = () => {
    setStudentModalVisibility(true);
  };

  const handleCloseStudentModalSecond = () => {
    setStudentModalVisibilitySecond(false);
    setIsOpenDownOpen(false); // Close the dropdown
  };

  const handleOpenStudentModalSecond = () => {
    setStudentModalVisibilitySecond(true);
    setIsOpenDownOpen(false); // Close the dropdown
  };

  const handleCloseStudentModalDelete = () => {
    setStudentModalVisibilityDel(false);
    setIsOpenDownOpen(false); // Close the dropdown
  };

  const handleOpenStudentModalDelete = () => {
    setStudentModalVisibilityDel(true);
    setIsOpenDownOpen(false); // Close the dropdown
  };

  const handleCloseStudentModal = () => {
    setStudentModalVisibility(false);
  };

  const toggleModal = () => {
    setIsOpenModal(prevState => !prevState);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return products.slice(startIndex, endIndex);
  };

  const renderFilters = () => {
    if (active) {
      // Return filters for Applications
      return (
        <div className="filtersLead">
          <div className="LeadXuyDataButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLead"
                className={`button-state-${XuyOdinState}`}
                onClick={() => PidorOdin(setXuyOdinState)}
              >
                <p>Date</p>
                <span>
                  {XuyOdinState === 1 && <IoIosArrowDown />}
                  {XuyOdinState === 2 && <IoIosArrowUp />}
                  {XuyOdinState === 3 && <AiOutlineCloseCircle />}
                  {XuyOdinState !== 1 && XuyOdinState !== 2 && XuyOdinState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="LeadSubjectSecondButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLead"
                className={`button-state-${XuyDvaState}`}
                onClick={() => PidorDwa(setXuyDvaState)}
              >
                <p>Subject</p>
                <span>
                  {XuyDvaState === 1 && <IoIosArrowDown />}
                  {XuyDvaState === 2 && <IoIosArrowUp />}
                  {XuyDvaState === 3 && <AiOutlineCloseCircle />}
                  {XuyDvaState !== 1 && XuyDvaState !== 2 && XuyDvaState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="LeadLessonTypeButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLead"
                className={`button-state-${XuyTriState}`}
                onClick={() => PidorTri(setXuyTriState)}
              >
                <p>Lesson type</p>
                <span>
                  {XuyTriState === 1 && <IoIosArrowDown />}
                  {XuyTriState === 2 && <IoIosArrowUp />}
                  {XuyTriState === 3 && <AiOutlineCloseCircle />}
                  {XuyTriState !== 1 && XuyTriState !== 2 && XuyTriState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="LeadSourceButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLead"
                className={`button-state-${XuyChetireState}`}
                onClick={() => PidorChetire(setXuyChetireState)}
              >
                <p>Application source</p>
                <span>
                  {XuyChetireState === 1 && <IoIosArrowDown />}
                  {XuyChetireState === 2 && <IoIosArrowUp />}
                  {XuyChetireState === 3 && <AiOutlineCloseCircle />}
                  {XuyChetireState !== 1 && XuyChetireState !== 2 && XuyChetireState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="LeadReset">
            <button onClick={resetAll}><VscDebugRestart /></button>
          </div>
        </div>
      );
    } else {
      // Return filters for Leads
      return (
        <div className="filtersLead">
          <div className="LeadDateButton_Box">
            <button className="LeadDateButton" onClick={handleButtonClick}>
              <p>
                {startDate && endDate
                  ? `${formatDate(startDate)} - ${formatDate(endDate)}`
                  : startDate
                    ? formatDate(startDate)
                    : "Added date"}
              </p>
              <span>
                {startDate || endDate ? (
                  <FaTimes onClick={handleResetDates} />
                ) : (
                  <FaChevronDown />
                )}
              </span>
            </button>
            {showDatePicker && (
              <div
                className="datepicker-container"
                style={{ width: isRange ? '545px' : '307px' }}
              >
                <div className="DataPickerTitle">
                  <h2>Date range</h2>
                  <p>Please select date range</p>
                </div>
                <div className="toggle-switch">
                  <label className="sukaebaniyLabel">
                    <input
                      type="checkbox"
                      checked={isRange}
                      onChange={handleToggle}
                    />
                    <p className="datapicker_input_text">Set the period</p>
                  </label>
                </div>
                {!isRange ? (
                  <div className="custom-datepicker">
                    <DatePicker
                      selected={tempStartDate}
                      onChange={handleSingleDateChange}
                      inline
                    />
                  </div>
                ) : (
                  <div className="range-datepickers">
                    <div className="custom-datepicker">
                      <DatePicker
                        selected={tempStartDate}
                        onChange={(date) => setTempStartDate(date)}
                        inline
                        placeholderText="Start Date"
                      />
                    </div>
                    <div className="custom-datepicker">
                      <DatePicker
                        selected={tempEndDate}
                        onChange={(date) => setTempEndDate(date)}
                        inline
                        placeholderText="End Date"
                        minDate={tempStartDate}
                      />
                    </div>
                  </div>
                )}
                <div className="button-group-lead-filter">
                  <button className="cancel-button-lead-filter" onClick={handleCancel}>
                    Cancel
                  </button>
                  <button className="apply-button-lead-filter" onClick={handleApply}>
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="LeadRoomButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLead"
                className={`button-state-${lessonTimeState}`}
                onClick={() => handleClick(setLessonTimeState)}
              >
                <p>Room</p>
                <span>
                  {lessonTimeState === 1 && <IoIosArrowDown />}
                  {lessonTimeState === 2 && <IoIosArrowUp />}
                  {lessonTimeState === 3 && <AiOutlineCloseCircle />}
                  {lessonTimeState !== 1 && lessonTimeState !== 2 && lessonTimeState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="LeadRoomButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLead"
                className={`button-state-${BranchState}`}
                onClick={() => handleClickSecond(setBranchState)}
              >
                <p>Branch</p>
                <span>
                  {BranchState === 1 && <IoIosArrowDown />}
                  {BranchState === 2 && <IoIosArrowUp />}
                  {BranchState === 3 && <AiOutlineCloseCircle />}
                  {BranchState !== 1 && BranchState !== 2 && BranchState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="teacherLeadButton">
            <div className="teacherLeadButton">
              <button className="Xuynyabattonlead" onClick={toggleDropdownTeacher}>
                <p>Teacher</p>
                <span className={`TeacherDropIcon ${isDropdownVisible ? 'activeTeacherLead' : ''}`}>
                  {isDropdownVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </button>
              {isDropdownVisible && (
                <div className="dropdown-menu-LeadTeacher">
                  <button>Mr. Ali</button>
                  <button>Mr. John</button>
                  <button>Ms. Hadicha</button>
                  <button>Mr. Temur</button>
                </div>
              )}
            </div>
          </div>
          <div className="LeadRoomButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLeadsecond"
                className={`button-state-${SubjectState}`}
                onClick={() => SubjectClick(setSubjectState)} >
                <p>Subject</p>
                <span>
                  {SubjectState === 1 && <IoIosArrowDown />}
                  {SubjectState === 2 && <IoIosArrowUp />}
                  {SubjectState === 3 && <AiOutlineCloseCircle />}
                  {SubjectState !== 1 && SubjectState !== 2 && SubjectState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="LeadRoomButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLeadThree"
                className={`button-state-${LessonTypeState}`}
                onClick={() => HandleLessonType(setLessonTypeState)} >
                <p>Lesson type</p>
                <span>
                  {LessonTypeState === 1 && <IoIosArrowDown />}
                  {LessonTypeState === 2 && <IoIosArrowUp />}
                  {LessonTypeState === 3 && <AiOutlineCloseCircle />}
                  {LessonTypeState !== 1 && LessonTypeState !== 2 && LessonTypeState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="LeadRoomButton">
            <div className="LeadRoomFilter">
              <button
                id="XuynyaebanayaLeadForm"
                className={`button-state-${FormState}`}
                onClick={() => handleLessonForm(setFormState)} >
                <p>Lead from</p>
                <span>
                  {FormState === 1 && <IoIosArrowDown />}
                  {FormState === 2 && <IoIosArrowUp />}
                  {FormState === 3 && <AiOutlineCloseCircle />}
                  {FormState !== 1 && FormState !== 2 && FormState !== 3 && <IoIosArrowDown />}
                </span>
              </button>
            </div>
          </div>
          <div className="LeadReset">
            <button onClick={resetAll}><VscDebugRestart /></button>
          </div>
        </div>
      );
    }
  };

  const content = !active ? (
    <div className="LeadsMain">
      {newLeadModal && <NewLeadModal setNewLeadModal={setNewLeadModal} />}
      <div className="LeadsMain_table">
        <button className="topBtn">New leads</button>
        <div className="LeadsMain_table_lists">
          {loadingActive ? <Loading /> : (
            leads.length > 0 && leads.map((item, index) => (
              <div className="LeadsMain_table_list" key={index}>
                {openDropdownIndex2 === index && isOpenModal && <LeadCard closeModal={closeModalOnOff} name={item.name} last_name={item.last_name} phone_number={item.phone_number} />}
                <div className="LeadsMain_table_list_top">
                  <p>{item.name ? item.name : "Tanlanmagan"} {item.last_name ? item.last_name : "Tanlanmagan"}</p>
                  <div className="dropdownLeadsMain">
                    <button onClick={() => toggleDropdownMenu(index)} className="dropdown-button">
                      <RiMoreFill />
                    </button>
                    {openDropdownIndex === index && (
                      <div className="dropdown-menu">
                        <Link to={`/Student-Page/${item.id}`}>
                          <button className="dropdown-item blue-dropduwn" onClick={() => setOpenDropdownIndex(null)}>
                            <span>
                              <RiShareBoxLine />
                            </span>
                            <h5>Open customer page</h5>
                          </button>
                        </Link>
                        <button className="dropdown-item blue-dropduwn" onClick={() => { handleOpenStudentModal(); setOpenDropdownIndex(null); }}>
                          <span>
                            <TbUserPlus />
                          </span>
                          <h5>Make a student</h5>
                        </button>
                        <button className="dropdown-item blue-dropduwn" onClick={() => { toggleModal(); setOpenDropdownIndex(null); }}>
                          <span>
                            <TbSend />
                          </span>
                          <h5>Send sms</h5>
                        </button>
                        <button className="dropdown-item red-dropdown" onClick={() => { handleOpenStudentModalSecond(); setOpenDropdownIndex(null); }}>
                          <span style={{ color: "#F7685B" }}>
                            <LuFolderDown />
                          </span>
                          <h5 style={{ color: "#F7685B" }}>Remove from board</h5>
                        </button>
                        <button className="dropdown-item red-dropdown" onClick={() => { handleOpenStudentModalDelete(); setOpenDropdownIndex(null); setLeadId(item.id) }}>
                          <span style={{ color: "#F7685B" }}>
                            <RiDeleteBin5Line />
                          </span>
                          <h5 style={{ color: "#F7685B" }}>Delete customer</h5>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {/* Agar modal ochilgan bo'lsa */}
                {isStudentModalVisibleDel && (
                  <DeleteCard onClose={handleCloseStudentModalDelete} itemId={leadId} getLead={getLead} />
                )}

                {isStudentModalVisibleSecond && (
                  <RemuveCard onClose={handleCloseStudentModalSecond} />
                )}

                {isStudentModalVisible && (
                  <MakeaStudent onClose={handleCloseStudentModal} />
                )}

                <div className="LeadsMain_table_list_phone">
                  <p>{item.phone_number ? item.phone_number : "Tanlanmagan"}</p>
                </div>

                <div className="LeadsMain_table_list_menu" onClick={() => toggleDropdownMenu2(index)}>
                  <div>
                    <button>
                      <SlGraduation />
                      {item.course ? item.course : "Tanlanmagan"}
                    </button>
                    <button>
                      <PiTelevisionSimple />
                      Type: {item.lesson_type ? item.lesson_type : "Tanlanmagan"}
                    </button>
                    <button>
                      <img src={item.created_by.image ? item.created_by.image : "default-image.png"} alt={item.created_by.username ? item.created_by.username : "Tanlanmagan"} style={{ borderRadius: "50%" }} />
                      {item.created_by.username ? item.created_by.username : "Tanlanmagan"}
                    </button>
                  </div>
                  <div>
                    <button>
                      <RiLinkUnlinkM />
                      {item.lead_source ? item.lead_source : "Tanlanmagan"}
                    </button>
                    <button>
                      <Icons.leadDate />
                      {item.date ? item.date : "Tanlanmagan"}
                    </button>
                    <button>
                      <TfiTime />
                      {item.lesson_time ? item.lesson_time : "Tanlanmagan"}
                    </button>
                  </div>
                </div>
              </div>
            ))
          )
          }
        </div>

      </div>
      <div className="LeadsMain_table">
        <button className="topBtn">Contacted</button>
        <div className="LeadsMain_table_lists">
          <div className="LeadsMain_table_list">
            <div className="LeadsMain_table_list_top">
              <p>Alisher Atajanov</p>
              <button>
                <RiMoreFill />
              </button>
            </div>
            <div className="LeadsMain_table_list_phone">
              <p>+998 99 966 73 63</p>
            </div>
            <div className="LeadsMain_table_list_menu">
              <div>
                <button>
                  <SlGraduation />
                  General english
                </button>
                <button>
                  <PiTelevisionSimple />
                  Type: Individual
                </button>
                <button>
                  <img src={img} alt="" />
                  Mr. Alibek
                </button>
              </div>
              <div>
                <button>
                  <RiLinkUnlinkM />
                  Instagram
                </button>
                <button>
                  <RxPinRight />
                  Room 2-2
                </button>
                <button>
                  <TfiTime />
                  08:00 - 09:00
                </button>
              </div>
            </div>
          </div>
          <div className="LeadsMain_table_list">
            <div className="LeadsMain_table_list_top">
              <p>Alisher Atajanov</p>
              <button>
                <RiMoreFill />
              </button>
            </div>
            <div className="LeadsMain_table_list_phone">
              <p>+998 99 966 73 63</p>
            </div>
            <div className="LeadsMain_table_list_menu">
              <div>
                <button>
                  <SlGraduation />
                  General english
                </button>
                <button>
                  <PiTelevisionSimple />
                  Type: Individual
                </button>
                <button>
                  <img src={img} alt="" />
                  Mr. Alibek
                </button>
              </div>
              <div>
                <button>
                  <RiLinkUnlinkM />
                  Instagram
                </button>
                <button>
                  <RxPinRight />
                  Room 2-2
                </button>
                <button>
                  <TfiTime />
                  08:00 - 09:00
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="LeadsMain_table">
        <button className="topBtn">Trial lesson</button>
        <div className="LeadsMain_table_lists">
          <div className="LeadsMain_table_list">
            <div className="LeadsMain_table_list_top">
              <p>Alisher Atajanov</p>
              <button>
                <RiMoreFill />
              </button>
            </div>
            <div className="LeadsMain_table_list_phone">
              <p>+998 99 966 73 63</p>
            </div>
            <div className="LeadsMain_table_list_menu">
              <div>
                <button>
                  <SlGraduation />
                  General english
                </button>
                <button>
                  <PiTelevisionSimple />
                  Type: Individual
                </button>
                <button>
                  <img src={img} alt="" />
                  Mr. Alibek
                </button>
              </div>
              <div>
                <button>
                  <RiLinkUnlinkM />
                  Instagram
                </button>
                <button>
                  <RxPinRight />
                  Room 2-2
                </button>
                <button>
                  <TfiTime />
                  08:00 - 09:00
                </button>
              </div>
            </div>
          </div>
          <div className="LeadsMain_table_list">
            <div className="LeadsMain_table_list_top">
              <p>Alisher Atajanov</p>
              <button>
                <RiMoreFill />
              </button>
            </div>
            <div className="LeadsMain_table_list_phone">
              <p>+998 99 966 73 63</p>
            </div>
            <div className="LeadsMain_table_list_menu">
              <div>
                <button>
                  <SlGraduation />
                  General english
                </button>
                <button>
                  <PiTelevisionSimple />
                  Type: Individual
                </button>
                <button>
                  <img src={img} alt="" />
                  Mr. Alibek
                </button>
              </div>
              <div>
                <button>
                  <RiLinkUnlinkM />
                  Instagram
                </button>
                <button>
                  <RxPinRight />
                  Room 2-2
                </button>
                <button>
                  <TfiTime />
                  08:00 - 09:00
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="LeadsMain_table">
        <button className="topBtn" id="summary">
          Summary
        </button>
        <div className="LeadsMain_table_lists">
          <div className="LeadsMain_table_list">
            <div className="LeadsMain_table_list_top">
              <p>Alisher Atajanov</p>
              <button>
                <RiMoreFill />
              </button>
            </div>
            <div className="LeadsMain_table_list_phone">
              <p>+998 99 966 73 63</p>
            </div>
            <div className="LeadsMain_table_list_menu">
              <div>
                <button>
                  <SlGraduation />
                  General english
                </button>
                <button>
                  <PiTelevisionSimple />
                  Type: Individual
                </button>
                <button>
                  <img src={img} alt="" />
                  Mr. Alibek
                </button>
              </div>
              <div>
                <button>
                  <RiLinkUnlinkM />
                  Instagram
                </button>
                <button>
                  <RxPinRight />
                  Room 2-2
                </button>
                <button>
                  <TfiTime />
                  08:00 - 09:00
                </button>
              </div>
            </div>
          </div>
          <div className="LeadsMain_table_list">
            <div className="LeadsMain_table_list_top">
              <p>Alisher Atajanov</p>
              <button>
                <RiMoreFill />
              </button>
            </div>
            <div className="LeadsMain_table_list_phone">
              <p>+998 99 966 73 63</p>
            </div>
            <div className="LeadsMain_table_list_menu">
              <div>
                <button>
                  <SlGraduation />
                  General english
                </button>
                <button>
                  <PiTelevisionSimple />
                  Type: Individual
                </button>
                <button>
                  <img src={img} alt="" />
                  Mr. Alibek
                </button>
              </div>
              <div>
                <button>
                  <RiLinkUnlinkM />
                  Instagram
                </button>
                <button>
                  <RxPinRight />
                  Room 2-2
                </button>
                <button>
                  <TfiTime />
                  08:00 - 09:00
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="LeadsTable">
      {loadingActive ? <Loading /> :
        <DataTable
          onClick={e => handleRowClick(e)}
          value={getPaginatedData()}
          selectionMode={rowClick ? null : 'checkbox'}
          selection={selectedProducts}
          onSelectionChange={handleSelectionChange}
          dataKey="id"
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
          <Column field="id" header="#" style={{ width: "32px", textAlign: "center" }} />
          <Column field="formatted_date" header="Date" style={{ width: "71px" }} className="AplicationData" />
          <Column field="first_name" header="Name" style={{ width: "120px" }} />
          <Column field="phone_number" header="Phone number" style={{ width: "155px" }} />
          <Column field="subject_type" header="Subject type" style={{ width: "141px" }} />
          <Column field="lesson_type" header="Lesson type" style={{ width: "121px" }} />
          <Column field="application_source" header="Application source" style={{ width: "165px" }} />
          <Column
            header=""
            body={(rowData) => (
              <Button
                icon="pi pi-ellipsis-v"
                className="p-button-text"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents row click handler from being triggered
                  menu.current.toggle(e);
                  handleIdClick(rowData.id)
                }}
              />
            )}
            style={{ width: "10px", borderRadius: "0 5px 5px 0" }}
          />
        </DataTable>
      }

      <Menu model={items} popup ref={menu} className="p-menu" />

      <div className="TablePaginator">
        <div className="TablePaginatorSelected">
          <p>Selected: {selectedProducts.length}</p> {/* Tanlanganlar sonini ko'rsatish */}
        </div>
        <div className="TablePaginatorButtons">
          <button
            className="TablePaginatorButtonsNextPage"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <Icons.leftArrow /> Previous page
          </button>
          <div className="TablePaginatorNumberButtons">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`TablePaginatorNumberButtonMini ${index + 1 === currentPage
                  ? "TablePaginatorNumberButtonActive"
                  : ""
                  }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <button
            className="TablePaginatorButtonsNextPage"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next page <Icons.rightArrowColor />
          </button>
        </div>
        <div className="TablePaginatorInput">
          <input
            type="number"
            placeholder="№"
            min="1"
            max={totalPages}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <p onClick={handleGoToPage}>Go to page</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="LeadsBox">
      <div className="LeadsNav">
        <div className="LeadsNav_chill">
          <div className="CourseLevelHeaderToggles">
            <p
              id="LeadsBar"
              onClick={() => setActive(false)}
              className={!active ? "activeLeadsBar" : ""}
            >
              Leads
            </p>
            <p
              id="ApplicationBar"
              onClick={() => setActive(true)}
              className={active ? "active" : ""}
            >
              Applications
            </p>
          </div>

          <div className="LeadsNav_chill_menuBar">
            <div
              onClick={toggleExpand}
              ref={searchContainerRef}
              className={`search-container ${expanded ? 'expanded' : ''}`}
            >
              <span className="search">
                <IoSearchSharp className={`search-icon ${expanded ? 'fade-out' : ''}`} />
              </span>
              {expanded && (
                <div className="XXXtenta">
                  <span id="XXXSpan"><IoSearchSharp /></span>
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Type something..."
                    autoFocus
                  />
                </div>
              )}
            </div>
            <button
              className="filter"
              onClick={() => setIsOpenFilter(!isOpenFilter)}
            >
              <TbFilter />
              <p>{isOpenFilter ? "Close filters" : "Filters"}</p>
            </button>
            {!active && (
              <button
                onClick={() => setNewLeadModal(!newLeadModal)}
                className="creted"
              >
                <FiUser />
                <p>New lead</p>
              </button>
            )}
            {showButtons && (
              <div className="ShowButtons">
                <button className="Make" onClick={handleOpenModal}>
                  <IoMdCheckmarkCircleOutline />
                  Make a lead
                </button>
                <button className="Delete" onClick={handleDeleteClick}>
                  <RiDeleteBin6Line />
                  Delete
                </button>
              </div>
            )}
            {showDeleteModal && (
              <DeleteModal
                onCancel={handleDeleteCancel}
                onDelete={handleDeleteConfirm}
              />
            )}

            {showSeconDel && (
              <SeconDel onClose={handleCloseModal} />
            )}

            {showModal && (
              <ModalMake onClose={handleCloseModal} onYes={handleYesModal} postLead={postLead}>
              </ModalMake>
            )}

            {showSecMake && (
              <SecMake onClose={handleCloseModal} />
            )}
          </div>
        </div>
        {isOpenFilter && renderFilters()}
      </div>
      <div className="LeadsContent">{content}</div>
    </div>
  );
};

export default Leads;

// Add CSS for selected row
const styles = `
          .selected-row {
            background - color: #E4F4FD !important;
          color: #005EEB !important; // Optional: change text color for better contrast
  }
          .selected-row td {
            color: #005EEB !important;   
}
          .selected-row .p-button.p-button-icon-only  {
            color: #005EEB !important;  
  }
          .selected-row tr td span  {
            color: #005EEB !important;  
  }
          `;

// Inject CSS styles into the document head
const styleElement = document.createElement("style");
styleElement.textContent = styles;
document.head.appendChild(styleElement);

