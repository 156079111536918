import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Aside.css";
import logo from "../../Assets/demo logo.png";
import profil from "../../Assets/teacher2.png";
import { IoChevronDownOutline, IoClipboardOutline } from "react-icons/io5";
import { HiOutlineDotsHorizontal, HiOutlineBookOpen } from "react-icons/hi";
import { RiPieChartLine } from "react-icons/ri";
import { BiGroup } from "react-icons/bi";
import { BsBank } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { GoChecklist } from "react-icons/go";

export default function Aside() {
  const location = useLocation();
  const currentPath = location.pathname;

  // Функция для определения активной кнопки по текущему пути
  const getActiveButtonIndex = () => {
    switch (currentPath) {
      case "/":
        return 1;
      case "/leads":
        return 2;
      case "/clients":
        return 3;
      case "/groups":
        return 5;
      case "/course":
        return 6;
      case "/personal":
        return 7;
      case "/finance-page":
        return 8;
      case "/Reports":
        return 9;
      case "/setting":
        return 10;
      default:
        return null;
    }
  };

  // Устанавливаем начальное состояние активной кнопки
  const [activeButton, setActiveButton] = useState(getActiveButtonIndex());
  const [isDropMainVisible, setIsDropMainVisible] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  

  // Обновляем активную кнопку при изменении пути
  useEffect(() => {
    setActiveButton(getActiveButtonIndex());
  }, [currentPath]);

  // Обрабатываем клик вне меню для его закрытия
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropMainVisible(false);
        setActiveButton(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsDropMainVisible(!isDropMainVisible);
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  const handleDropdownButtonClick = (branch) => {
    setSelectedBranch(branch);
  };

  return (
    <div className="Aside">
      <div className="MenuLeft" ref={menuRef}>
        <div className="MenuLeftLogo">
          <img src={logo} alt="Logo" />
        </div>
        <div style={{ position: 'relative' }}>
          <div className={`MenuLeftUser ${isDropMainVisible ? 'Xuyactive' : ''}`} onClick={handleToggleDropdown}>
            <div className="MenuLeftContant">
              <div className="MenuLeftUser-img">
                <img src={profil} alt="Profile" />
              </div>
              <div className="MenuLeftText">
                <h3>Main branch</h3>
                <p>Nukus city</p>
              </div>
            </div>
            <div className="MenuLefticon">
              <IoChevronDownOutline />
            </div>
          </div>
          {isDropMainVisible && (
            <div className="DropdownMenuMain" ref={dropdownRef}>
              <button
                onClick={() => handleDropdownButtonClick("Samarkand branch")}
                className={selectedBranch === "Samarkand branch" ? "selected" : "DropdownMenuButton"}
              >
                {selectedBranch === "Samarkand branch" && <span className="checkmark">✔</span>}
                Samarkand branch
              </button>
              <button
                onClick={() => handleDropdownButtonClick("Tashkent branch")}
                className={selectedBranch === "Tashkent branch" ? "selected" : "DropdownMenuButton"}
              >
                {selectedBranch === "Tashkent branch" && <span className="checkmark">✔</span>}
                Tashkent branch
              </button>
              <button
                onClick={() => handleDropdownButtonClick("Main branch")}
                className={selectedBranch === "Main branch" ? "selected" : "DropdownMenuButton"}
              >
                {selectedBranch === "Main branch" && <span className="checkmark">✔</span>}
                Main branch
              </button>
              <button
                onClick={() => handleDropdownButtonClick("Khorezm branch")}
                className={selectedBranch === "Khorezm branch" ? "selected" : "DropdownMenuButton"}
              >
                {selectedBranch === "Khorezm branch" && <span className="checkmark">✔</span>}
                Khorezm branch
              </button>
            </div>
          )}
        </div>

        <div className="MenuLeftButtons">
          <Link to={"/"}>
            <div
              className={`MenuButton ${activeButton === 1 ? "active" : ""}`}
              onClick={() => handleButtonClick(1)}
            >
              <div className="imgiconbox">
                <MdOutlineDashboard />
              </div>
              <p>Dashboard</p>
            </div>
          </Link>
          <Link to={"/leads"}>
            <div
              className={`MenuButton ${activeButton === 2 ? "active" : ""}`}
              onClick={() => handleButtonClick(2)}
            >
              <div className="imgiconbox">
                <IoClipboardOutline />
              </div>
              <p>Leads</p>
            </div>
          </Link>
          <Link to={"/clients"}>
            <div
              className={`MenuButton ${activeButton === 3 ? "active" : ""}`}
              onClick={() => handleButtonClick(3)}
            >
              <div className="imgiconbox">
                <FaRegUser />
              </div>
              <p>Clients</p>
            </div>
          </Link>
          <Link to={"/groups"}>
            <div
              className={`MenuButton ${activeButton === 5 ? "active" : ""}`}
              onClick={() => handleButtonClick(5)}
            >
              <div className="imgiconbox">
                <BiGroup />
              </div>
              <p>Groups</p>
            </div>
          </Link>
          <Link to={"/course"}>
            <div
              className={`MenuButton ${activeButton === 6 ? "active" : ""}`}
              onClick={() => handleButtonClick(6)}
            >
              <div className="imgiconbox">
                <HiOutlineBookOpen />
              </div>
              <p>Course</p>
            </div>
          </Link>
          <Link to={"/personal"}>
            <div
              className={`MenuButton ${activeButton === 7 ? "active" : ""}`}
              onClick={() => handleButtonClick(7)}
            >
              <div className="imgiconbox">
                <GoChecklist />
              </div>
              <p>Personnel</p>
            </div>
          </Link>
          <Link to={"/finance-page"}>
            <div
              className={`MenuButton ${activeButton === 8 ? "active" : ""}`}
              onClick={() => handleButtonClick(8)}
            >
              <div className="imgiconbox">
                <BsBank />
              </div>
              <p>Finance</p>
            </div>
          </Link>
          <Link to={"/Reports"}>
            <div
              className={`MenuButton ${activeButton === 9 ? "active" : ""}`}
              onClick={() => handleButtonClick(9)}
            >
              <div className="imgiconbox">
                <RiPieChartLine />
              </div>
              <p>Reports</p>
            </div>
          </Link>
        </div>
        <div className="MenuLeftButtons zayebalmenu">
          <Link to={"/setting"}>
            <div className="settongButtonBox">
              <div
                className={`MenuButton ${activeButton === 10 ? "active" : ""}`}
                onClick={() => handleButtonClick(10)}
              >
                <div className="imgiconbox">
                  <HiOutlineDotsHorizontal />
                </div>
                <p>Settings</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="ContentRight">
        <div className="Navbar"></div>
      </div>
    </div>
  );
}
