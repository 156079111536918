import { useState, useRef } from 'react';
import './PersonalContactEdit.css';
import { FaRegCircleCheck, FaAngleUp, FaAngleDown } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';

export default function PersonalContactEdit({ onClose }) {
    const [step, setStep] = useState(1);
    const [cardNumber, setCardNumber] = useState('');
    const [birthday, setBirthday] = useState('');
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('+998');

    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const formattedValue = inputValue.replace(/[^+\d]/g, '');
        setPhoneNumber(formattedValue);
    };

    const dateInputRef = useRef(null);



    const handleDateBlur = () => {
        setIsDatePickerOpen(false);
    };

    const handleDateChange = (e) => {
        setBirthday(e.target.value);
        setIsDatePickerOpen(false);
    };

    const handleIconClick = () => {
        if (isDatePickerOpen) {
            dateInputRef.current.blur();
        } else {
            dateInputRef.current.showPicker();
        }
        setIsDatePickerOpen(!isDatePickerOpen);
    };

    return (
        <div className="PersonalInformationEdit">
            {step === 1 && (
                <div className="PersonalInformationEditStepOne">
                    <div className="ConFimModalAddStudClose" onClick={onClose}><IoClose /></div>
                    <div className="PersonalInformationEditStepOneTitle">
                        <h2>Edit contacts</h2>
                        <p>Change employee contacts</p>
                    </div>
                    <div className="PersonalInformationEditStepOneButtons">
                        <label htmlFor="tel-number">
                            <p>Phone number (secondary)</p>
                            <input
                                className="CardNumInp"
                                type="tel"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                maxLength="13"
                            />
                        </label>
                        <label htmlFor="birthday">
                            <p>Telegram profile (example: username)</p>
                            <input type="text" className='CardNumInp' placeholder='@telegram' />
                        </label>

                        <label htmlFor="">
                            <p>Instagram profile (example: username)</p>
                            <input type="text" className='CardNumInp' placeholder='@instagram' />
                        </label>

                        <label htmlFor="address">
                            <p>E-mail (example: email@example.com)</p>
                            <input type="text" className='CardNumInp' placeholder='E-mail adress' />
                        </label>
                    </div>

                    <div className="PersonalInformationEditStepOneButtonsSave">
                        <button onClick={onClose}>Cancel</button>
                        <button onClick={() => setStep(2)}>Save</button>
                    </div>
                </div>
            )}

            {step === 2 && (
                <div className="PersonalInformationEditStepTwo">
                    <div className="ConFimModalAddStudClose" onClick={onClose}><IoClose /></div>
                    <div className="PersonalInformationEditStepTwoTitle PersonalInformationEditStepTwoTitleSec">
                        <h2>Change contacts</h2>
                        <p>Do you confirm saving the changed contacts ?</p>
                    </div>
                    <div className="PersonalInformationEditStepOneButtonsSaveTwo">
                        <button onClick={() => setStep(1)}>Go back</button>
                        <button onClick={() => {
                            setStep(3);
                            setTimeout(() => {
                                onClose();
                            }, 2000);
                        }}>Yes</button>
                    </div>
                </div>
            )}

            {step === 3 && (
                <div className="PersonalInformationEditStepThree">
                    <div className="ConFimModalAddStudLogo">
                        <span><FaRegCircleCheck /></span>
                    </div>
                    <div className="PersonalInformationEditStepThreeButton">
                        <h2>Saved</h2>
                        <p>All changes saved!</p>
                    </div>
                </div>
            )}
        </div>
    );
}
