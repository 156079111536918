import { useState, useRef } from 'react';
import './PersonalInformationEdit.css';
import { FaRegCircleCheck, FaAngleUp, FaAngleDown } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';

export default function PersonalInformationEdit({ onClose }) {
    const [step, setStep] = useState(1);
    const [cardNumber, setCardNumber] = useState('');
    const [birthday, setBirthday] = useState('');
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false); // Состояние для открытия датапикера

    const dateInputRef = useRef(null);

    const formatCardNumber = (value) => {
        const digitsOnly = value.replace(/\D/g, '');
        return digitsOnly.replace(/(\d{4})(?=\d)/g, '$1 ');
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedValue = formatCardNumber(inputValue);
        setCardNumber(formattedValue);
    };

    const handleDateClick = () => {
        if (dateInputRef.current) {
            dateInputRef.current.showPicker(); // Открываем календарь
            setIsDatePickerOpen(true); // Меняем состояние при открытии
        }
    };

    const handleDateBlur = () => {
        // При убирании фокуса закрываем пикер и меняем иконку
        setIsDatePickerOpen(false);
    };

    const handleDateChange = (e) => {
        setBirthday(e.target.value);
        setIsDatePickerOpen(false); // Закрываем датапикер и меняем стрелку на вниз
    };

    const handleIconClick = () => {
        if (isDatePickerOpen) {
            // Если датапикер открыт, при клике на иконку закрываем его
            dateInputRef.current.blur();
        } else {
            // Если датапикер закрыт, открываем его при клике на иконку
            dateInputRef.current.showPicker();
        }
        setIsDatePickerOpen(!isDatePickerOpen); // Переключаем состояние
    };

    return (
        <div className="PersonalInformationEdit">
            {step === 1 && (
                <div className="PersonalInformationEditStepOne">
                    <div className="ConFimModalAddStudClose" onClick={onClose}><IoClose /></div>
                    <div className="PersonalInformationEditStepOneTitle">
                        <h2>Edit employee information</h2>
                        <p>Change all employee information</p>
                    </div>
                    <div className="PersonalInformationEditStepOneButtons">
                        <label htmlFor="card-number">
                            <p>Card number</p>
                            <input
                                className='CardNumInp'
                                type="text"
                                placeholder="Enter card number"
                                value={cardNumber}
                                onChange={handleInputChange}
                                maxLength="19"
                            />
                        </label>
                        <label htmlFor="birthday">
                            <p>Birthday</p>
                            <div className="date-input-wrapper">
                                <input
                                    type="date"
                                    className="custom-date-input"
                                    ref={dateInputRef}
                                    onClick={handleDateClick}
                                    onChange={handleDateChange}
                                    onBlur={handleDateBlur}
                                    value={birthday}
                                />
                                <div className="date-controls">
                                    {isDatePickerOpen ? (
                                        <FaAngleUp onClick={handleIconClick} className="icon-control" />
                                    ) : (
                                        <FaAngleDown onClick={handleIconClick} className="icon-control" />
                                    )}
                                </div>
                            </div>
                        </label>

                        <label htmlFor="">
                            <p>Gender</p>
                            <select name="gender" id="gender">
                                <option value="Select" defaultChecked>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </label>

                        <label htmlFor="address">
                            <p>Address</p>
                            <input className='EnterAdressInp' type="text" placeholder="Enter address" />
                        </label>
                    </div>

                    <div className="PersonalInformationEditStepOneButtonsSave">
                        <button onClick={onClose}>Cancel</button>
                        <button onClick={() => setStep(2)}>Save</button>
                    </div>
                </div>
            )}

            {step === 2 && (
                <div className="PersonalInformationEditStepTwo">
                    <div className="ConFimModalAddStudClose" onClick={onClose}><IoClose /></div>
                    <div className="PersonalInformationEditStepTwoTitle">
                        <h2>Change information</h2>
                        <p>Do you confirm saving<br />
                            the changed information?</p>
                    </div>
                    <div className="PersonalInformationEditStepOneButtonsSaveTwo">
                        <button onClick={() => setStep(1)}>Go back</button>
                        <button onClick={() => {
                            setStep(3);
                            setTimeout(() => {
                                onClose();
                            }, 2000);
                        }}>Yes</button>
                    </div>
                </div>
            )}

            {step === 3 && (
                <div className="PersonalInformationEditStepThree">
                    <div className="ConFimModalAddStudLogo">
                        <span><FaRegCircleCheck /></span>
                    </div>
                    <div className="PersonalInformationEditStepThreeButton">
                        <h2>Saved</h2>
                        <p>All changes saved!</p>
                    </div>
                </div>
            )}
        </div>
    );
}
