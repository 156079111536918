import React, { useState } from "react";
import "./Setting.css";
import Dragger from "antd/es/upload/Dragger";
import { Button, Checkbox, Input, message, Modal, Radio, Select } from "antd";
import { Icons } from "../../Assets/icons/icons";
import checkBg from "../../Assets/chekBg.png";
import { CiShare1 } from "react-icons/ci";
export default function Setting() {
  const [activeDiv, setActiveDiv] = useState(1);

  const toggleDiv = (divNumber) => {
    setActiveDiv(divNumber);
  };

  const props = {
    name: "file",
    multiple: true,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [valueCustom, setValueCustom] = useState(1);
  const onChangeCustom = (e) => {
    console.log("radio checked", e.target.value);
    setValueCustom(e.target.value);
  };

  const [valueNumber, setValueNumber] = useState(1);
  const onChangeNumber = (e) => {
    console.log("radio checked", e.target.value);
    setValueNumber(e.target.value);
  };

  const [valueCustomNumber, setValueCustomNumber] = useState(1);
  const onChangeCustomNumber = (e) => {
    console.log("radio checked", e.target.value);
    setValueCustomNumber(e.target.value);
  };

  const [valueType, setValueType] = useState(0);
  const onChangeType = (e) => {
    console.log("radio checked", e.target.value);
    setValueType(e.target.value);
  };

  const [valueCustomType, setValueCustomType] = useState(1);
  const onChangeCustomType = (e) => {
    console.log("radio checked", e.target.value);
    setValueCustomType(e.target.value);
  };

  const [valueLanguange, setValueLanguange] = useState(1);
  const onChangeLanguange = (e) => {
    console.log("radio checked", e.target.value);
    setValueLanguange(e.target.value);
  };

  const onChangeChek = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [paymentType, setPaymentType] = useState(null);
  const [sendSms, setSendSms] = useState(false);
  const [isAccessGiven, setIsAccessGiven] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = () => {
    if (login && password && paymentType) {
      setIsAccessGiven(true);
      setIsModalVisible(false);
    }
  };

  const handleDeleteAccess = () => {
    setLogin("");
    setPassword("");
    setPaymentType(null);
    setSendSms(false);
    setIsAccessGiven(false);
    setIsModalVisible(false);
  };
  return (
    <div className="CoursePage Setting">
      <div className="CourseLevelHeaderToggles">
        <p
          id="ActiveP"
          onClick={() => toggleDiv(1)}
          className={activeDiv === 1 ? "active" : ""}
        >
          Profile
        </p>
        <p
          id="ActiveP"
          onClick={() => toggleDiv(2)}
          className={activeDiv === 2 ? "active" : ""}
        >
          Basic
        </p>
        <p
          id="ActiveP"
          onClick={() => toggleDiv(3)}
          className={activeDiv === 3 ? "active" : ""}
        >
          Sms
        </p>
        <p
          id="ActiveP"
          onClick={() => toggleDiv(4)}
          className={activeDiv === 4 ? "active" : ""}
        >
          Payment
        </p>
        <p
          id="ActiveP"
          onClick={() => toggleDiv(5)}
          className={activeDiv === 5 ? "active" : ""}
        >
          The check
        </p>
        <p
          id="ActiveP"
          onClick={() => toggleDiv(6)}
          className={activeDiv === 6 ? "active" : ""}
        >
          Members
        </p>
        <p
          id="ActiveP"
          onClick={() => toggleDiv(7)}
          className={activeDiv === 7 ? "active" : ""}
        >
          Billing
        </p>
      </div>

      {activeDiv === 1 && (
        <div className="SettingChildrenDiv">
          <span className="SettingChildrenDivTitle">Profile</span>

          <div className="SettingForms">
            <div className="SettingForm">
              <div className="SettingFormAvatar">
                <h1>A</h1>
              </div>
            </div>
            <div className="SettingFormInfo">
              <p className="SettingFormInfoTitle">File Upload Label Link</p>
              <div className="SettingForm">
                <Dragger {...props}>
                  <div className="SettingFormUpload">
                    <span>Drag and drop here or</span>
                    <p>Browse Files</p>
                  </div>
                </Dragger>
              </div>
            </div>

            <div className="SettingFormInfo">
              <p className="SettingFormInfoTitle">Last name*</p>
              <div className="SettingForm">
                <input type="text" placeholder="Enter code" />
              </div>
            </div>

            <div className="SettingFormInfo">
              <p className="SettingFormInfoTitle">Last name*</p>
              <div className="SettingForm">
                <input type="text" placeholder="Enter code" />
              </div>
            </div>

            <div className="SettingFormInfo">
              <p className="SettingFormInfoTitle">Phone number*</p>
              <div className="SettingForm">
                <input type="tel" placeholder="+998" />
              </div>
            </div>

            <div className="SettingFormInfo">
              <p className="SettingFormInfoTitle">Who is the employee?</p>
              <div className="SettingForm">
                <input
                  type="text"
                  placeholder="Example: founder or marketer..."
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {activeDiv === 2 && (
        <div className="SettingChildrenDiv">
          <span className="SettingChildrenDivTitle">Basic settings</span>
          <div className="SettingChildrenDivBeetwen">
            <div className="SettingForms">
              <div className="SettingForm">
                <div className="SettingFormAvatar">
                  <h1>A</h1>
                </div>
              </div>
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">File Upload Label Link</p>
                <div className="SettingForm">
                  <Dragger {...props}>
                    <div className="SettingFormUpload">
                      <span>Drag and drop here or</span>
                      <p>Browse Files</p>
                    </div>
                  </Dragger>
                </div>
              </div>
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Name of company</p>
                <div className="SettingForm">
                  <input type="text" placeholder="Enter code" />
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Phone number of company</p>
                <div className="SettingForm">
                  <input type="tel" placeholder="+998" />
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">
                  Upload offer (recommended: .pdf, .jpeg; max-size: 5mb;)
                </p>
                <div className="SettingForm">
                  <Dragger {...props}>
                    <div className="SettingFormUpload">
                      <span>Drag and drop here or</span>
                      <p>Browse Files</p>
                    </div>
                  </Dragger>
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Who is the employee?</p>
                <div className="SettingForm">
                  <input
                    type="text"
                    placeholder="Example: founder or marketer..."
                  />
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Working days</p>
                <div className="SettingForm">
                  <Select
                    defaultValue="select"
                    style={{
                      width: "100%",
                      height: "45px",
                      borderRadius: "10px",
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: "select",
                        label: "Select",
                      },
                      {
                        value: "lucy",
                        label: "Lucy",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Working days</p>
                <div className="SettingForm">
                  <Select
                    defaultValue="select"
                    style={{
                      width: "100%",
                      height: "45px",
                      borderRadius: "10px",
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: "select",
                        label: "Select",
                      },
                      {
                        value: "lucy",
                        label: "Lucy",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  />
                  <Select
                    defaultValue="select"
                    style={{
                      width: "100%",
                      height: "45px",
                      borderRadius: "10px",
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: "select",
                        label: "Select",
                      },
                      {
                        value: "lucy",
                        label: "Lucy",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Select branches</p>
                <div className="SettingForm">
                  <Select
                    defaultValue="select"
                    style={{
                      width: "100%",
                      height: "45px",
                      borderRadius: "10px",
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: "select",
                        label: "Select",
                      },
                      {
                        value: "lucy",
                        label: "Lucy",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className="SettingChildrenDivRight">
              <div className="SettingChildrenDivRightTitle">
                <p>Basic system settings</p>
              </div>
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Select branches</p>
                <div className="SettingFormRadios">
                  <Radio.Group
                    onChange={onChange}
                    value={value}
                    className="SettingUnitRadio1"
                  >
                    <Radio value={1}>Point</Radio>
                    <Radio value={2}>Unit</Radio>
                    <Radio value={3}>Iq</Radio>
                    <Radio value={4}>Power</Radio>
                  </Radio.Group>

                  <Radio.Group
                    onChange={onChangeCustom}
                    value={valueCustom}
                    className="SettingUnitRadio2"
                  >
                    <Radio value={1}>Star</Radio>
                    <Radio value={2}>Custom</Radio>
                    <input type="text" placeholder="Unit name..." />
                  </Radio.Group>
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Rating scale</p>
                <div className="SettingFormRadios">
                  <Radio.Group
                    onChange={onChangeNumber}
                    value={valueNumber}
                    className="SettingUnitRadio1"
                  >
                    <Radio value={1}>5</Radio>
                    <Radio value={2}>10</Radio>
                    <Radio value={3}>100</Radio>
                  </Radio.Group>

                  <Radio.Group
                    onChange={onChangeCustomNumber}
                    value={valueCustomNumber}
                    className="SettingUnitRadio2"
                  >
                    <Radio value={1}>Star</Radio>
                    <Radio value={2}>
                      <input type="text" placeholder="Unit name..." />
                    </Radio>
                  </Radio.Group>
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">
                  Type of adding monthly salary to employee balance
                </p>
                <div className="SettingFormRadios">
                  <Radio.Group
                    onChange={onChangeType}
                    value={valueType}
                    className="SettingUnitRadio1"
                  >
                    <Radio value={1}>Add daily amount to balance</Radio>
                  </Radio.Group>

                  <Radio.Group
                    onChange={onChangeCustomType}
                    value={valueCustomType}
                    className="SettingUnitRadio2"
                  >
                    <Radio value={1}>One day of every month</Radio>
                    <Radio value={2}>
                      <input type="text" placeholder="Unit name..." />
                    </Radio>
                  </Radio.Group>
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">
                  Add rooms (The room word is required)
                </p>
                <div className="SettingFormList">
                  <input type="text" placeholder="Room" />
                  <button>Add</button>
                </div>
                <div className="SettingFormTodos">
                  <div className="SettingFormTodo">
                    <p>1 Room</p>
                    <div className="SettingFormTodoActions">
                      <Icons.basicEdit />
                      <Icons.basicDelet />
                    </div>
                  </div>
                </div>

                <div className="SettingFormTodos">
                  <div className="SettingFormTodo">
                    <p>2 Room</p>
                    <div className="SettingFormTodoActions">
                      <Icons.basicEdit />
                      <Icons.basicDelet />
                    </div>
                  </div>
                </div>

                <div className="SettingFormTodos">
                  <div className="SettingFormTodo">
                    <p>3 Room</p>
                    <div className="SettingFormTodoActions">
                      <Icons.basicEdit />
                      <Icons.basicDelet />
                    </div>
                  </div>
                </div>

                <div className="SettingFormTodos">
                  <div className="SettingFormTodo">
                    <p>4 Room</p>
                    <div className="SettingFormTodoActions">
                      <Icons.basicEdit />
                      <Icons.basicDelet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeDiv === 3 && <div>Div 3 Content</div>}
      {activeDiv === 4 && (
        <div className="SettingChildrenDiv">
          <span className="SettingChildrenDivTitle">Payment settings</span>
          <div className="SettingPaymentInputs">
            <div className="SettingForms">
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Click merchant ID</p>
                <div className="SettingForm">
                  <input type="text" placeholder="Enter code" />
                </div>
              </div>
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Payme merchant ID</p>
                <div className="SettingForm">
                  <input type="text" placeholder="Enter code" />
                </div>
              </div>
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Payme merchant ID</p>
                <div className="SettingForm">
                  <input type="text" placeholder="Enter code" />
                </div>
              </div>
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Uzum merchant ID</p>
                <div className="SettingForm">
                  <input type="text" placeholder="Enter code" />
                </div>
              </div>
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Alif merchant ID</p>
                <div className="SettingForm">
                  <input type="text" placeholder="Enter code" />
                </div>
              </div>
            </div>
            <div className="SettingForms">
              <div className="SettingFormInfoSelect">
                <p className="SettingFormInfoTitle">Select branches</p>
                <Select
                  defaultValue="select"
                  style={{
                    width: "100%",
                    height: "45px",
                    borderRadius: "10px",
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "select",
                      label: "Select",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </div>
              <div className="SettingFormInfoSelect">
                <p className="SettingFormInfoTitle">Select branches</p>
                <Select
                  defaultValue="select"
                  style={{
                    width: "100%",
                    height: "45px",
                    borderRadius: "10px",
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "select",
                      label: "Select",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </div>
              <div className="SettingFormInfoSelect">
                <p className="SettingFormInfoTitle">Select branches</p>
                <Select
                  defaultValue="select"
                  style={{
                    width: "100%",
                    height: "45px",
                    borderRadius: "10px",
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "select",
                      label: "Select",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </div>
              <div className="SettingFormInfoSelect">
                <p className="SettingFormInfoTitle">Select branches</p>
                <Select
                  defaultValue="select"
                  style={{
                    width: "100%",
                    height: "45px",
                    borderRadius: "10px",
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "select",
                      label: "Select",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </div>
              <div className="SettingFormInfoSelect">
                <p className="SettingFormInfoTitle">Select branches</p>
                <Select
                  defaultValue="select"
                  style={{
                    width: "100%",
                    height: "45px",
                    borderRadius: "10px",
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "select",
                      label: "Select",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="SettingPaymentButtons">
            <button className="CourseInfoHeaderButtonOne">Send sms</button>
            <button className="CourseInfoHeaderButtonTwo">
              Add new employee
            </button>
          </div>
        </div>
      )}
      {activeDiv === 5 && (
        <div className="SettingChildrenDiv">
          <span className="SettingChildrenDivTitle">
            Billing check settings
          </span>
          <div className="settingChildrenChekBeetwenn">
            <div className="SettingForms">
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Billing check languange</p>
                <div className="SettingForm">
                  <Radio.Group
                    onChange={onChangeLanguange}
                    value={valueLanguange}
                    className="SettingUnitRadio1"
                  >
                    <Radio value={1}>English</Radio>
                    <Radio value={2}>Uzbek</Radio>
                    <Radio value={3}>Russian</Radio>
                    <Radio value={4}>Qaraqalpaq</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="SettingForm">
                <div className="SettingFormAvatar">
                  <h1>A</h1>
                </div>
              </div>
              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">Logo for billing check</p>
                <div className="SettingForm">
                  <Dragger {...props}>
                    <div className="SettingFormUpload">
                      <span>Drag and drop here or</span>
                      <p>Browse Files</p>
                    </div>
                  </Dragger>
                </div>
              </div>

              <div className="SettingFormInfo">
                <p className="SettingFormInfoTitle">
                  Print additional information on the receipt as well
                </p>
                <div className="SettingForm">
                  <div className="SettingFormCheks">
                    <Checkbox onChange={onChangeChek}>Logo of company</Checkbox>
                    <Checkbox onChange={onChangeChek}>Name of company</Checkbox>
                    <Checkbox onChange={onChangeChek}>Phone number</Checkbox>
                    <Checkbox onChange={onChangeChek}>Branch</Checkbox>
                    <Checkbox onChange={onChangeChek}>Teacher</Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div className="SettingCheckRight">
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SettingChecksDiv">
            <div className="SettingChecksDivHeader">
              <p>Preview for other transaction’s check</p>
              <span>Transaction check for students</span>
            </div>
            <div className="SettingChecks">
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SettingChecksDiv">
            <div className="SettingChecksDivHeader">
              <span>Transaction check for students</span>
            </div>
            <div className="SettingChecks">
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SettingChecksDiv">
            <div className="SettingChecks">
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
              <div className="SettingCheck">
                <img src={checkBg} alt="" className="SettingCheckBg" />
                <div className="settingChekInfos">
                  <div className="settingChekInfosHeader">
                    <h1>Logo of company</h1>
                    <p>Name of company</p>
                    <h1>Payment check</h1>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Transaction’s id: 28323</p>
                    <p>Branch: Main branch</p>
                    <p>Student: Alisher Atajanov</p>
                    <p>Phone number: +998999667363</p>
                    <p>Course: English TTS 14:00 Mr. Johnson</p>
                    <p>Teacher: Mr. Johnson</p>
                    <p>Monthly payment: 330 000 so’m</p>
                    <p>All depts: 410 000 so’m</p>
                    <p>Payment: 280 000 so’m</p>
                    <p>Payment type: Cash</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Indebtedness:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 330 000 so’m</p>
                    <p>All indebtedness: 410 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Deducted:</p>
                    <p>March: 80 000 so’m</p>
                    <p>April: 200 000 so’m</p>
                    <p>Current balance: - 130 000 so’m</p>
                  </div>
                  <div className="settingChekInfosParagraph">
                    <p>Employee: John Anderson</p>
                    <p>Date: 06.06.2024 16:16</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeDiv === 6 && (
        <div className="SettingChildrenDiv">
          <div className="SettingChildrenDivHeader">
            <span className="SettingChildrenDivTitle">Members</span>
            <div className="SettingChildrenDivHeaderSearch">
              <span>All employees: 48</span>
              <span>Have access to system: 23</span>
              <div className="SettingChildrenDivHeaderSearchInupt">
                <Icons.search />
                <input type="text" placeholder="Search..." />
              </div>
            </div>
          </div>
          <div className="SettingMembers">
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="SettingMember">
              <div className="SettingMemberInfo">
                <div className="SettingMemberName">
                  <p>1</p>
                  <p className="mrAAAA">
                    Mr. Aleksey{" "}
                    <span>
                      <CiShare1 />
                    </span>
                  </p>
                </div>
                <div className="SettingMemberBrach">
                  <input type="text" placeholder="Role in business" />
                  <Select
                    defaultValue="Branches"
                    className="membersSelect"
                    style={{
                      width: "210px",
                      height: "40px!important",
                      padding: "0",
                    }}
                    onChange={(value) => console.log(value)}
                    options={[
                      { value: "Branches", label: "Branches" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "Yiminghe" },
                    ]}
                  />
                </div>
              </div>
              <div className="SettingMemberButton">
                <div onClick={showModal}>
                  {isAccessGiven ? (
                    <button className="SettingMemberButtonEditAccess">
                      <Icons.editModal /> Edit access
                    </button>
                  ) : (
                    <button className="SettingMemberButtonAddAccess">
                      <Icons.LinkButton /> Add access to system
                    </button>
                  )}
                </div>
              </div>

              <Modal
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="custom-modal"
              >
                <div className="setting-modal-content">
                  <div className="modal-header-titles">
                    <span>Edit employee access to system</span>
                    <p className="modal-subtitle">
                      Editing custom access employee to system
                    </p>
                  </div>
                  <div className="SettingModalFroms">
                    <div className="SettingModalFrom">
                      <p>Login</p>
                      <Input
                        className="SettingModalFromInput"
                        placeholder="Login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      />
                    </div>

                    <div className="SettingModalFrom">
                      <p>Password</p>
                      <Input.Password
                        className="SettingModalFromInput"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="setting-modal-section">
                    <p className="setting-modal-section-title">Payment type:</p>
                    <Radio.Group
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymentType}
                      className="modal-radios"
                    >
                      <Radio value="Administrator">Administrator</Radio>
                      <Radio value="Main manager">Main manager</Radio>
                      <Radio value="Branch manager">Branch manager</Radio>
                      <Radio value="Finance manager">Finance manager</Radio>
                      <Radio value="Marketing manager">Marketing manager</Radio>
                      <Radio value="Teacher">Teacher</Radio>
                      <Radio value="Reception administrator">
                        Reception administrator
                      </Radio>
                    </Radio.Group>
                  </div>
                  <Checkbox
                    checked={sendSms}
                    onChange={(e) => setSendSms(e.target.checked)}
                    className="setting-modal-checkbox"
                  >
                    Send login and password to employee with SMS
                  </Checkbox>
                  <div className="setting-modal-footer">
                    {isAccessGiven ? "" : <div className=""></div>}
                    {isAccessGiven && (
                      <Button
                        onClick={handleDeleteAccess}
                        className="delete-button"
                      >
                        Delete access
                      </Button>
                    )}
                    <div className="setting-modal-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleSave}
                        className="save-button"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      )}
      {activeDiv === 7 && <div>Div 7 Content</div>}
    </div>
  );
}
