import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { CiShare1 } from "react-icons/ci";
import { FaRegCirclePause, FaStar } from "react-icons/fa6";
import { HiChevronUpDown } from "react-icons/hi2";
import { LuPlayCircle, LuUser } from "react-icons/lu";
import { Link } from "react-router-dom";
import { Icons } from "../../Assets/icons/icons";
import { HiDotsVertical } from "react-icons/hi";
import DeleteTableModal from "../ClientComponents/ClientModals/DeleteTableModal";
import ArchiveTableModal from "../ClientComponents/ClientModals/ArchiveTableModal";
import AddIsTableModal from "../ClientComponents/ClientModals/AddIsTableModal";
import ClientSendSmsModal from "../ClientComponents/ClientModals/ClientSendSmsModal";
import AddSalaryModal from "../ClientComponents/ClientModals/AddSalaryModal";
import IndividualLesson from "../ClientComponents/ClientModals/IndividualLesson";
import ChangeStatusModal from "../ClientComponents/ClientModals/ChangeStatusModal";
import AddToGroupModal from "../ClientComponents/ClientModals/AddToGroupModal";
import UnarchiveTableModal from "../ClientComponents/ClientModals/UnarchiveTableModal";
import EditTableModal from "./GroupModal/EditTableModal";
import Loading from "../../Pages/Loading";
import UnarchiveTableModalGroup from "./GroupModal/UnarchiveTableModalGroup";

export default function ArchiveGroupTable({
  Groups,
  selectAll,
  setSelectAll,
  activeRows,
  setActiveRows,
  loadingArchive,
  getActive,
  getArchive
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [archiveId, setArchiveId] = useState(null);

  useEffect(() => {
    setSelectAll(activeRows.length === Groups.length);
  }, [activeRows, Groups.length]);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setActiveRows([]);
    } else {
      setActiveRows(Groups.map((group) => group.id));
    }
  };

  const handleRowCheckboxChange = (event, groupId) => {
    event.stopPropagation();
    const updatedActiveRows = activeRows.includes(groupId)
      ? activeRows.filter((id) => id !== groupId)
      : [...activeRows, groupId];
    setActiveRows(updatedActiveRows);
  };

  const handleRowClick = (groupId) => {
    handleRowCheckboxChange({ stopPropagation: () => { } }, groupId);
  };
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(null); // Track the open dropdown by client ID
  const totalPages = Math.ceil(Groups.length / rowsPerPage);
  const itemsPerPage = 10;

  const handlePageChange = (page) => {
    if (page === "next" && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (page === "prev" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else if (typeof page === "number") {
      setCurrentPage(page);
    }
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputValue);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleDropdownToggle = (clientId) => {
    setIsOpen((prev) => (prev === clientId ? null : clientId));
  };

  const DropdownMenu = ({ clientId }) =>
    isOpen === clientId && (
      <div className=" dropdown-menu-table-action">
        <button
          style={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
          className="dropdown-item blue-dropduwn"
          onClick={() => {
            setEditTable(true);
            setIsOpen(false);
          }}
        >
          <span>
            <Icons.editTable />
          </span>
          <h5>Edit</h5>
        </button>
        <button
          className="dropdown-item blue-dropduwn"
          onClick={() => {
            SetsendSms(true);
            setIsOpen(false);
          }}
        >
          <span>
            <Icons.sendSms />
          </span>
          <h5>Send sms</h5>
        </button>
        <button
          className="dropdown-item blue-dropduwn"
          onClick={() => {
            setUnarchiveModalQuest(true);
            setIsOpen(false);
            setArchiveId(clientId)
          }}
        >
          <span>
            <Icons.unarchive />
          </span>
          <h5>Unarchive</h5>
        </button>
        <button
          style={{
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
          className="dropdown-item red-dropdown"
          onClick={() => {
            setCustomerModalQuest(true);
            setIsOpen(false);
          }}
        >
          <span style={{ color: "#F7685B" }}>
            <Icons.deleteRed />
          </span>
          <h5 style={{ color: "#F7685B" }}>Delete</h5>
        </button>
      </div>
    );

  const displayedClients = Groups.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const [sendSms, SetsendSms] = useState(false);
  const [customerModalQuest, setCustomerModalQuest] = useState(false);
  const [UnarchiveModalQuest, setUnarchiveModalQuest] = useState(false);
  const [editTable, setEditTable] = useState(false);

  return (
    <div className="CliensTables">
      <div className="clientPoastionModals">
        <EditTableModal editTable={editTable} setEditTable={setEditTable} />
        <DeleteTableModal
          customerModalQuest={customerModalQuest}
          setCustomerModalQuest={setCustomerModalQuest}
        />
        <ClientSendSmsModal sendSms={sendSms} SetsendSms={SetsendSms} />
        <UnarchiveTableModalGroup
          UnarchiveModalQuest={UnarchiveModalQuest}
          setUnarchiveModalQuest={setUnarchiveModalQuest}
          archiveId={archiveId}
          getActive={getActive}
          getArchive={getArchive}
        />
      </div>
      <div className="GlavTable scale">
        <span className="GlavTablCheckSpan">
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAllChange}
            onClick={(e) => e.stopPropagation()}
          ></Checkbox>
        </span>
        <span className="NomberHeaderTitles tableHeaderTitles">
          <p>#</p>
        </span>
        <span
          className="NameHeaderTitles tableHeaderTitles"
          style={{
            width: "170px",
          }}
        >
          <p style={{ marginLeft: "5px" }}>Group Name</p>
          <div className="chervon">
            <HiChevronUpDown />
          </div>
        </span>
        <span className="tableHeaderTitles" style={{ width: "90px" }}>
          <p>Points</p>
          <div
            className="chervon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiChevronUpDown />
          </div>
        </span>
        <span
          className="tableHeaderTitles"
          style={{
            width: "130px",
          }}
        >
          <p>Course</p>
          <div
            className="chervon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiChevronUpDown />
          </div>
        </span>
        <span
          className="tableHeaderTitles"
          style={{
            fontSize: "12px",
            width: "126px",
          }}
        >
          <p
            style={{
              color: "black",
              fontSize: "12px",
            }}
          >
            Teacher
          </p>
          <div
            className="chervon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiChevronUpDown />
          </div>
        </span>
        <span
          className="tableHeaderTitles"
          style={{
            fontSize: "12px",
            width: "91px",
          }}
        >
          <p
            style={{
              color: "black",
              fontSize: "12px",
            }}
          >
            Days
          </p>
          <div
            className="chervon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiChevronUpDown />
          </div>
        </span>
        <span
          className="tableHeaderTitles"
          style={{
            fontSize: "12px",
            width: "107px",
          }}
        >
          <p
            style={{
              color: "black",
              fontSize: "12px",
            }}
          >
            Dates
          </p>
          <div
            className="chervon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiChevronUpDown />
          </div>
        </span>
        <span
          className="tableHeaderTitles"
          style={{
            fontSize: "12px",
            width: "97px",
          }}
        >
          <p
            style={{
              color: "black",
              fontSize: "12px",
            }}
          >
            Room
          </p>
          <div
            className="chervon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiChevronUpDown />
          </div>
        </span>
        <span
          className="tableHeaderTitles"
          style={{
            fontSize: "12px",
            width: "83px",
          }}
        >
          <p
            style={{
              color: "black",
              fontSize: "12px",
            }}
          >
            Students
          </p>
          <div
            className="chervon"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiChevronUpDown />
          </div>
        </span>
      </div>
      <div className="ClientTable">
        {loadingArchive ? (
          <div className="LoadingMainDiv">
            <Loading />
          </div>
        ) : (displayedClients.map((group, index) => (
          <div
            key={group.id}
            className={`GlavTable scale ${activeRows.includes(group.id) ? "ClientTableActive" : ""
              }`}
            onClick={() => handleRowClick(group.id)}
          >
            <span className="GlavTablCheckSpan">
              <Checkbox
                checked={activeRows.includes(group.id)}
                onChange={(e) => handleRowCheckboxChange(e, group.id)}
              ></Checkbox>
            </span>
            <span className="Nomber">
              <p>{group.id}</p>
              <span style={{ color: "#2ED47A", marginTop: 5 }}>
                <Icons.archivePause />
              </span>
            </span>
            <span
              className="Name"
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="Name_P">
                {group.group_name}
                <Link
                  className={`linktoStudpage ${activeRows.includes(group.id) ? "viewLInkFlex" : ""
                    }`}
                  to="/group-profil"
                >
                  <span>
                    <CiShare1 />
                  </span>
                </Link>
              </p>
            </span>
            <span
              className="Points axuetzayoba"
              style={{ gap: "5px", marginRight: "20px" }}
            >
              <b className="BStar">
                <FaStar />
              </b>
              <div className="chervon">
                <b style={{ fontSize: "12px", color: "#707683" }}>
                  {group.points}
                </b>
              </div>
            </span>
            <span
              className="Course"
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "143px",
                marginRight: "0",
              }}
            >
              <p style={{ fontSize: "14px" }}>
                Intermediate:{/* {group.level} */}
              </p>
              <div className="chervon">
                <p style={{ fontSize: "10px" }}>{group.course.name}</p>
              </div>
            </span>
            <span
              className="Teacher TeacherTableLink"
              style={{ width: "100px" }}
            >
              <p className="TeacherTableLinkTitle">
                {group.teacher_fk_user === null ? "None" : group.teacher_fk_user.first_name}
                <span className="">
                  <CiShare1 />
                </span>
              </p>
            </span>
            <span
              className="Days"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "91px",
              }}
            >
              <p style={{ fontSize: "14px" }}>{group.days}</p>
              <div className="chervon">
                <p style={{ fontSize: "10px" }}>{group.start_time}</p>
              </div>
            </span>
            <span
              className="Dates"
              style={{
                width: "110px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <p style={{ fontSize: "14px" }}>{group.start_day}-</p>
              <div className="chervon">
                <p style={{ fontSize: "10px" }}>{group.end_day}</p>
              </div>
            </span>
            <span
              className="Room"
              style={{ width: "97px", transform: "translateX(-8px)" }}
            >
              <p style={{ fontSize: "14px" }}>{group.room}</p>
              <p
                style={{
                  width: "auto",
                  color: "#C2CFE0",
                  fontSize: "18px",
                }}
              ></p>
            </span>
            <span
              className="Students"
              style={{ width: "98px", justifyContent: "space-between" }}
            >
              <p>
                <LuUser />
                12{/* {group.countStudents} */}
              </p>
              <p onClick={() => handleDropdownToggle(group.id)}>
                <HiDotsVertical />
              </p>
            </span>
            <DropdownMenu clientId={group.id} />
          </div>
        )))}
      </div>
      <div className="NavigationTool">
        <div className=""></div>
        <div className="TablePaginatorButtons">
          <button
            className="TablePaginatorButtonsNextPage"
            onClick={() => handlePageChange("prev")}
            disabled={currentPage === 1}
          >
            <Icons.leftArrow /> Previous page
          </button>
          <div className="TablePaginatorNumberButtons">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`TablePaginatorNumberButtonMini ${currentPage === index + 1
                  ? "TablePaginatorNumberButtonActive"
                  : ""
                  }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            {totalPages > 3 && (
              <button className="TablePaginatorNumberButtonMini">...</button>
            )}
          </div>
          <button
            className="TablePaginatorButtonsNextPage"
            onClick={() => handlePageChange("next")}
            disabled={currentPage === totalPages}
          >
            Next page <Icons.rightArrowColor />
          </button>
        </div>
        <div className="TablePaginatorInput">
          <input
            type="number"
            placeholder="№"
            min="1"
            max={totalPages}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <p onClick={handleGoToPage}>Go to page</p>
        </div>
      </div>
    </div>
  );
}
