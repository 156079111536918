import "./loading.css"

export default function Loading() {
    return (
       /* From Uiverse.io by adamgiebl */ 
<section class="dots-container">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</section>

    )
}
