import "./Navbar.css";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import { PiClockClockwise } from "react-icons/pi";
import { HiLanguage } from "react-icons/hi2";
import { BsArrowsFullscreen } from "react-icons/bs";
import { IoSearchSharp } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoChevronDownOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import profilImg from "../../Assets/teacher.png";
import NewLeadCard from "../../Pages/leads/NewLeadCard";
import { FaChevronRight } from "react-icons/fa6";
import { GrMoney } from "react-icons/gr";
import { FiUser } from "react-icons/fi";
import { TbMessageQuestion } from "react-icons/tb";
import { LuSettings2 } from "react-icons/lu";
import { IoIosLogOut } from "react-icons/io";
import plus from "./../../Assets/icons/Plus.png";
import Modal from "react-modal";
import students from "./../../Assets/graduation-hat-01.png";
import lupa from "./../../Assets/lupa.png";
import { GoArrowRight } from "react-icons/go";
import { IoMdSearch } from "react-icons/io";

export default function Navbar() {
  const [showContent, setShowContent] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const boxes = [
    { id: 1, content: "Alisher Atajanov +998 99 966 73 63 ...  " },
    { id: 2, content: "Alisher Atajanov +998 99 966 73 63 ... " },
    { id: 3, content: "Alisher Atajanov +998 99 966 73 63 ...  " },
    { id: 4, content: "Alisher Atajanov +998 99 966 73 63 ...  " },
    { id: 5, content: "Alisher Atajanov +998 99 966 73 63 ...  " },
    { id: 6, content: "Alisher Atajanov +998 99 966 73 63 ...  " },
    { id: 7, content: "Alisher Atajanov +998 99 966 73 63 ...  " },
    { id: 8, content: "Alisher Atajanov +998 99 966 73 63 ...  " },
    { id: 9, content: "Alisher Atajanov +998 99 966 73 63 ...  " },
    // Добавьте больше боксов по необходимости
  ];

  const filteredBoxes = boxes.filter((box) =>
    box.content.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const handleSelectContent = (content) => {
    setSelectedContent(content);
  };

  const [isActive, setIsActive] = useState({
    Khodjeyli: false,
    Main: false,
    Success: false,
  });

  const toggleIsActive = (button) => {
    setIsActive((prevState) => ({
      ...prevState,
      [button]: !prevState[button],
    }));
  };

  const [plusModal, setPlusModal] = useState(false);
  const [notficetion, setNotficetion] = useState(false);
  const [history, setHistory] = useState(false);
  const [profil, setProfil] = useState(false);

  function handleLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    window.location.reload();
    console.log("logout");
  }

  return (
    <div className="Navbar">
      {plusModal ? (
        <NewLeadCard setPlusModal={setPlusModal} plusModal={plusModal} />
      ) : null}
      <div className="BranchSelect">
        <div className="BranchButtonDrop">
          <button onClick={toggleContent}>Dashboard</button>
        </div>
      </div>
      <div className="NavButtonBox">
        <button className="IoSearch" onClick={openModal}>
          <IoSearchSharp />
          <p>Global search</p>
        </button>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Search Modal"
          className="modal ModalSearch"
          overlayClassName="ModalSearch-overlay"
          onClick={(e) => {
            if (e.target.className === 'ModalSearch-overlay') {
              closeModal();
            }
          }}
        >
          <div className="SearchInpuBox">
            <span><IoMdSearch /></span>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="ModalSearch-input"
            />
          </div>
          <div className="ModalSearch-container">
            {filteredBoxes.length > 0 ? (
              filteredBoxes.map((box) => (
                <div key={box.id} className="boxSearch">
                  <div className="aghhhh">
                    {box.content}{" "}
                    <div className="AOAO">
                      <p>-</p>
                      <span style={{ marginLeft: "13px" }}>
                        <img src={students} alt="" />
                      </span>{" "}
                      <p style={{ color: "rgba(51, 169, 255, 1)" }}>Students</p>
                    </div>
                  </div>
                  <div className="aaaaa">
                    <GoArrowRight />
                  </div>
                </div>
              ))
            ) : (
              <div className="Nothink">
                <div className="nothinkimg">
                  <img src={lupa} alt="" />
                </div>
                <div className="NothinkText">
                  <p>Nothing found</p>
                </div>
              </div>
            )}
          </div>
        </Modal>
        <button onClick={() => setPlusModal(!plusModal)} className="GoPlus">
          <img src={plus} alt="" />
        </button>
        <button className="BsArrows">
          <BsArrowsFullscreen />
        </button>
        <button className="Notification">
          <IoIosNotificationsOutline
            onClick={() => setNotficetion(!notficetion)}
          />
          {notficetion && (
            <div className="notficationDrop">
              <p className="notficationDrop_title">Notfications</p>
              <div className="notficationDrop_Messege">
                <div className="notficationDrop_Messege_box">
                  <div className="notficationDrop_Messege_box_top">
                    <h3>Say hello to new update</h3>
                    <button>
                      Click to open <FaChevronRight />
                    </button>
                  </div>
                  <div className="notficationDrop_Messege_box_min">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                  </div>
                  <p className="notficationDrop_Messege_box_time">
                    02.04.2024 18:42
                  </p>
                </div>
                <div className="notficationDrop_Messege_box">
                  <div className="notficationDrop_Messege_box_top">
                    <h3>Sey hello to new update</h3>
                    <button>
                      Click to open <FaChevronRight />
                    </button>
                  </div>
                  <div className="notficationDrop_Messege_box_min">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,{" "}
                    </p>
                  </div>
                  <p className="notficationDrop_Messege_box_time">
                    02.04.2024 18:42{" "}
                  </p>
                </div>
                <div className="notficationDrop_Messege_box">
                  <div className="notficationDrop_Messege_box_top">
                    <h3>Sey hello to new update</h3>
                    <button>
                      Click to open <FaChevronRight />
                    </button>
                  </div>
                  <div className="notficationDrop_Messege_box_min">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,{" "}
                    </p>
                  </div>
                  <p className="notficationDrop_Messege_box_time">
                    02.04.2024 18:42{" "}
                  </p>
                </div>
                <div className="notficationDrop_Messege_box">
                  <div className="notficationDrop_Messege_box_top">
                    <h3>Sey hello to new update</h3>
                    <button>
                      Click to open <FaChevronRight />
                    </button>
                  </div>
                  <div className="notficationDrop_Messege_box_min">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,{" "}
                    </p>
                  </div>
                  <p className="notficationDrop_Messege_box_time">
                    02.04.2024 18:42{" "}
                  </p>
                </div>
                <div className="notficationDrop_Messege_box">
                  <div className="notficationDrop_Messege_box_top">
                    <h3>Sey hello to new update</h3>
                    <button>
                      Click to open <FaChevronRight />
                    </button>
                  </div>
                  <div className="notficationDrop_Messege_box_min">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,{" "}
                    </p>
                  </div>
                  <p className="notficationDrop_Messege_box_time">
                    02.04.2024 18:42{" "}
                  </p>
                </div>
                <div className="notficationDrop_Messege_box">
                  <div className="notficationDrop_Messege_box_top">
                    <h3>Sey hello to new update</h3>
                    <button>
                      Click to open <FaChevronRight />
                    </button>
                  </div>
                  <div className="notficationDrop_Messege_box_min">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s,{" "}
                    </p>
                  </div>
                  <p className="notficationDrop_Messege_box_time">
                    02.04.2024 18:42{" "}
                  </p>
                </div>
              </div>
            </div>
          )}
        </button>
        <button className="Piclock">
          <PiClockClockwise onClick={() => setHistory(!history)} />
          {history && (
            <div className="notficationDrop">
              <p className="notficationDrop_title">History of operations</p>
              <div className="notficationDrop_Messege">
                <div className="notficationDrop_Messege_box">
                  <div className="notficationDrop_Messege_box_top">
                    <h3>Say hello to new update</h3>
                    <button style={{ color: "#C2CFE0" }}>John Anderson</button>
                  </div>
                  <div className="notficationDrop_Messege_box_min">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p>
                  </div>
                  <p className="notficationDrop_Messege_box_time">
                    02.04.2024 18:42
                  </p>
                </div>
              </div>
            </div>
          )}
        </button>
        <div className="Navprofil" onClick={() => setProfil(!profil)}>
          <div className="blablabla">
            <img src={profilImg} alt="Profile" />
            <div className="profilInfo">
              <p className="profilInfo_lastname">Baxtiyorov X</p>
              <p className="profilInfo_frstname">Founder</p>
            </div>
          </div>
          {profil && (
            <div
              className="Navprofil_drop"
              onClick={(e) => e.stopPropagation()}
            >
              <button>
                <FiUser />
                My profil
              </button>
              <button>
                <GrMoney />
                Billing
              </button>
              <button>
                <TbMessageQuestion />
                Technique support
              </button>
              <button>
                <LuSettings2 />
                Settings
              </button>
              <button onClick={handleLogout}>
                <IoIosLogOut />
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
