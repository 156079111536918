import { IoClose } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";


export default function NewLeadModal({ setNewLeadModal }) {

    const [showSelect, setShowSelect] = useState(false)
    const [subjectState, setSubjectState] = useState(0);
    const [lessonTypeState, setLessonTypeState] = useState(0);
    const [teacherState, setTeacherState] = useState(0);
    const [lessonTimeState, setLessonTimeState] = useState(0);
    const [leadSourceState, setLeadSourceState] = useState(0);
    const [leadSourceesheState, setLeadSourceesheState] = useState(0);

    // General click handler
    const handleClick = (setState) => {
        setState(prevState => (prevState + 1) % 4);
    };

    // Get button text based on state
    const getButtonText = (state) => {
        switch (state) {
            case 1:
                return 'Option 1';
            case 2:
                return 'Option 2';
            case 3:
                return 'Option 3';
            default:
                return 'Sobject';
        }
    };

    return (
        <div className="newLeaadCardCon">
            <div className="newLeaadCard2">
                <button className='CloseOneni' onClick={() => setNewLeadModal(false)}><IoClose /></button>
                <div className="AddLeadModal_title">
                    <p>Add new lead</p>
                    <p>By creating a new lead, you will also be adding a new customer to customer base</p>
                </div>
                <div className="newLeaadCard_studentMenu1_chil_form one1">
                    <div>
                        <label htmlFor="">First name*</label>
                        <input placeholder="John" type="text" />
                    </div>
                    <div>
                        <label htmlFor="">Last name*</label>
                        <input placeholder="Anderson" type="text" />
                    </div>
                    <div>
                        <label htmlFor="">Phone number*</label>
                        <input defaultValue="+998" type="text" />
                    </div>
                    <div className="menuChil_information__chil">
                        <label htmlFor="lessonType">Select lesson type*</label>
                        <div className="LeadModalCardAdd">
                            <button
                                id="Xuynyaebanaya"
                                className={`button-state-eshe-${leadSourceesheState}`}
                                onClick={() => handleClick(setLeadSourceesheState)}
                            >
                                <p>{getButtonText(leadSourceesheState)}</p>
                                <span>
                                    {leadSourceesheState === 1 && <IoIosArrowDown />}
                                    {leadSourceesheState === 2 && <IoIosArrowUp />}
                                    {leadSourceesheState === 3 && <AiOutlineCloseCircle />}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="showMore" onClick={() => setShowSelect(!showSelect)}>
                    <p>Show more</p>
                    <div></div>
                    {
                        showSelect ? (
                            <IoIosArrowUp />
                        ) : (
                            <IoIosArrowDown />
                        )
                    }
                </div>
                {
                    showSelect && (
                        <div className="newLeaadCard_studentMenu1_chil_form one1">
                            <div className="menuChil_information__chil">
                                <label htmlFor="lessonType">Select lesson type*</label>
                                <div className="LeadModalCardAdd">
                                    <button
                                        id="Xuynyaebanaya"
                                        className={`button-state-${lessonTypeState}`}
                                        onClick={() => handleClick(setLessonTypeState)}
                                    >
                                        <p>{getButtonText(lessonTypeState)}</p>
                                        <span>
                                            {lessonTypeState === 1 && <IoIosArrowDown />}
                                            {lessonTypeState === 2 && <IoIosArrowUp />}
                                            {lessonTypeState === 3 && <AiOutlineCloseCircle />}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="menuChil_information__chil">
                                <label htmlFor="teacher">Select teacher*</label>
                                <div className="LeadModalCardAdd">
                                    <button
                                        id="Xuynyaebanaya"
                                        className={`button-state-${teacherState}`}
                                        onClick={() => handleClick(setTeacherState)}
                                    >
                                        <p>{getButtonText(teacherState)}</p>
                                        <span>
                                            {teacherState === 1 && <IoIosArrowDown />}
                                            {teacherState === 2 && <IoIosArrowUp />}
                                            {teacherState === 3 && <AiOutlineCloseCircle />}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="menuChil_information__chil">
                                <label htmlFor="lessonTime">Select lesson time*</label>
                                <div className="LeadModalCardAdd">
                                    <button
                                        id="Xuynyaebanaya"
                                        className={`button-state-${lessonTimeState}`}
                                        onClick={() => handleClick(setLessonTimeState)}
                                    >
                                        <p>{getButtonText(lessonTimeState)}</p>
                                        <span>
                                            {lessonTimeState === 1 && <IoIosArrowDown />}
                                            {lessonTimeState === 2 && <IoIosArrowUp />}
                                            {lessonTimeState === 3 && <AiOutlineCloseCircle />}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="menuChil_information__chil">
                                <label htmlFor="leadSource">Select lead source*</label>
                                <div className="LeadModalCardAdd">
                                    <button
                                        id="Xuynyaebanaya"
                                        className={`button-state-${leadSourceState}`}
                                        onClick={() => handleClick(setLeadSourceState)}
                                    >
                                        <p>{getButtonText(leadSourceState)}</p>
                                        <span>
                                            {leadSourceState === 1 && <IoIosArrowDown />}
                                            {leadSourceState === 2 && <IoIosArrowUp />}
                                            {leadSourceState === 3 && <AiOutlineCloseCircle />}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="ChekNewLeadModal">
                    <input type="checkbox" />
                    <p>Add to lead board</p>
                </div>

                <div className="LeadsCard_bottomBtns">
                    <button id="save">Confrim</button>
                    <button onClick={() => setNewLeadModal(false)}>Cancel</button>
                </div>
            </div>
        </div>
    )
}